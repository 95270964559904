import React from "react";
import MenuAppBar from "../../components/MenuAppBar";
import "./AdminPage.css";
import Grid from '@mui/material/Grid';
import AdminDashboard from "../../components/Admin/AdminDashboard";
import { Box } from "@mui/system";

function AdminPage() {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);

  const toggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };

  return (
    <>
      <MenuAppBar handleBtnClick={toggleSidebar} />
      <Box sx={{marginTop:"5%", position: 'relative', top: '64px' }}>
        <Grid container justifyContent="center" spacing={3}>
          <AdminDashboard />
        </Grid>
      </Box>
    </>
  );
}
export default AdminPage;
