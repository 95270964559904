import React from "react";
import { Box } from "@mui/system";
import { Grid } from "@material-ui/core";
import { Typography, IconButton } from "@mui/material";
import ReusableTable from "../utils/ReusableTable";
import { useAuthDispatch, getUsers, deleteUser, updateUser, userRegister, getTasks } from "../../context";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularIndeterminate from "../utils/CircularIndeterminate"
import EmptyPopUp from "../utils/EmptyPopup"
import ReusableButton from "../utils/ReusableButton";
import PopUp from "../utils/Popup";
import swal from 'sweetalert';
import UserForm from "../Usuarios/UsersForm";
import TareasList from "../Tareas/TareasList";

const AdminDashboard = () => {
  const dispatch = useAuthDispatch(); 
  const [tasks, setTasks] = React.useState([]);
  const [users, setUsers] = React.useState([]);
  const [error, setError] = React.useState();
  const [openFormPopUp, setOpenFormPopUp] = React.useState(false);
  const [openEmptyPopup, setOpenEmptyPopup] = React.useState(false);
  const [openUsuariosPopup, setOpenUsuariosPopup] = React.useState(false);
  const [openFormEdit, setOpenFormEdit] = React.useState(false);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [idUser, setIdUser] = React.useState(null)


  // const handleEdit = (idUser) => {
  //   setSelectedUser(idUser);
  //   setOpenFormEdit(true);
  // };


  const handleEdit = (idUser, userData) => {
    console.log("User data:", userData)
    setIdUser(idUser);
    setSelectedUser(userData)
    setOpenFormEdit(true);
  };
  const handleDelete = async (id) => {
    try {
      swal({
        title: "¿Está seguro que quiere eliminar este usuario?",
        text: "Una vez eliminado, no será posible recuperarlo.",
        icon: "warning",
        buttons: ["Cancelar", "Eliminar"],
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          await deleteUser(dispatch, id);
          fetchUsers();
          swal("Usuario eliminado satisfactoriamente!", {
            icon: "success",
          });
        } else {
          swal("Eliminación cancelada.");
        }
      });
    } catch (error) {
      // Maneja el error y muestra una notificación de error si es necesario
    }
  };

  const fetchUsers = async () => {
    try {
      setOpenEmptyPopup(true);
      const users = await getUsers(dispatch);
      setUsers(users.info);
      setOpenEmptyPopup(false);
    } catch {
      setError("Error al cargar los datos");
      setUsers([]);
    }
  };

  React.useEffect(() => {
    fetchUsers();
    return () => {
      setUsers([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  // Puedes mantener esta dependencia si es necesario

  const fetchTasks = async () => {
    try {
      setOpenEmptyPopup(true);
      let response = await getTasks(dispatch);
      setTasks(response.info);
      setOpenEmptyPopup(false);
// Add this line
    } catch {
      setError("Error al cargar los datos");
      setTasks([]);
    }
  };

  React.useEffect(() => {

    fetchTasks();
    return () => {
      setTasks([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []); 

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Nombre",
        accessor: "firstName",
        canSort: true,
        type: "String",
      },
      {
        Header: "Apellido",
        accessor: "lastName",
        canSort: true,
        type: "String",
      },
      {
        Header: "Rol",
        accessor: "role",
        canSort: true,
        type: "String",
      },
      {
        Header: "Usuario",
        accessor: "username",
        canSort: true,
        type: "String",
      },
      {
        Header: "Editar",
        accessor: "editar",
        canSort: false,
        type: "EditButton",
      },
      {
        Header: "Eliminar",
        accessor: "eliminar",
        canSort: false,
        type: "DeleteButton",
      },
    ],
    []
  );

  const columnsToShowInitially = ['ID', 'Nombre', 'Apellido', 'Rol', 'Usuario', 'Editar', 'Eliminar'];

  const handleRefresh = async (event) => {
    fetchUsers();
  };
 
  const editUser = async (idUser, values) => {
    await updateUser(idUser, values);
    setOpenFormEdit(false);
    fetchUsers();
  };

  const handleSubmit = async (values) => {
    await userRegister(values);
    setOpenFormPopUp(false);
    fetchUsers();
  };

  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto', padding: '1rem', flexGrow: 1, marginLeft:'50px' }}>
    {(error !== "") && (<div className="error">{error}</div>)}
      
    <Grid container spacing={2}>
      
      <Grid item>
        <IconButton
          aria-label="refresh"
          onClick={() => {
            handleRefresh();
          }}
        >
          <RefreshIcon />
        </IconButton>
      </Grid>
      <Grid item>
        <ReusableButton
          text="Nuevo usuario"
          variant="outlined"
          color="primary"
          onClick={() => setOpenFormPopUp(true)}
          type="button"
        />
      </Grid>
      <Grid item>
      <Typography variant="h5" sx={{ textAlign: "left" }}>
            Lista de Usuarios
          </Typography>
      </Grid>
    </Grid>
      <PopUp
        title="Ingresar Usuario"
        openPopup={openFormPopUp}
        setOpenPopup={setOpenFormPopUp}
      >
        <UserForm addUser={handleSubmit} />
      </PopUp>

      <PopUp
        title="Editar Usuario"
        openPopup={openFormEdit}
        setOpenPopup={setOpenFormEdit}
      >
        <UserForm 
            editMode={editUser} 
            idUser={idUser}
            initialValues={selectedUser}  />
      </PopUp>

      <EmptyPopUp
        title="Cargando..."
        openPopup={openUsuariosPopup}
        setOpenPopup={setOpenUsuariosPopup}
      >
        <CircularIndeterminate />
      </EmptyPopUp>

      <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={6} style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
          <div style={{ overflowX: 'auto' }}>
            <ReusableTable
              columns={columns}
              data={users || []}
              columnsToShowInitially={columnsToShowInitially}
              onEditButtonClick={handleEdit}
              onDeleteButtonClick={handleDelete}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={6} style={{ marginLeft: 'auto', marginRight: 'auto', marginTop: 'auto', marginBottom: 'auto' }}>
          <Typography variant="h5" style={{ textAlign: "left", marginLeft:"50px"}}>
            Lista de Tareas
          </Typography>
          <div style={{ overflowX: 'auto'}}>
            <TareasList tasks={tasks} users={users} />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminDashboard;