import React from 'react';
import { FormControl, Grid, TextField, Paper, Button, Checkbox, FormControlLabel, Select, MenuItem, InputLabel,IconButton } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers';
import { Box } from '@mui/system';
import { UseForm, Form } from '../utils/UseForm';
import { useAuthDispatch, getIndustries, useAuthState, getClients, setClient, setIndustry } from "../../context";
import { makeStyles } from '@material-ui/core';
import { FormHelperText } from '@material-ui/core';
import AddIcon from '@mui/icons-material/Add';
import PopUp from "../utils/Popup"
import IndustryForm from '../Industrias/IndustriasForm';
import ClientForm from '../Clientes/ClientesForm';
import swal from "sweetalert";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInputBase-input': {
      fontSize: '1.0rem',
      lineHeight: 1.5,
      paddingTop: '1rem',
      paddingBottom: '1rem',
      minHeight: '10rem',
      background: "#ffffff",
    }
  }
}));


const potencialOptions = [
    { value: "prometedor", label: "Prometedor" },
    { value: "factible", label: "Factible" },
    { value: "incierto", label: "Incierto" },
  ]
const estadoOptions = [
    { value: "seguimiento", label: "Seguimiento" },
    { value: "sin interés", label: "Sin interés" },
    { value: "diferido", label: "Diferido" },
    { value: "completa", label: "Completa" }
]



export default function TaskForm (props) {


    const {initialValues, addTask, editMode, idTask} = props

    const initialFieldValues = {
      titulo:  "",
      potencial: "",
      llamada: false,
      estado: "",
      visita_presencial: false,
      fecha_recordatorio: "",
      texto_recordatorio: "",
      descripcion: "",
      email: false,
      clienteId: null,
      industriaId: null,
      usuarioId: null,
    };

    const classes = useStyles();

   
    const state = useAuthState();
    const dispatch = useAuthDispatch();
    const [clients, setClients] = React.useState([]);
    const [industries, setIndustries] = React.useState([]);
    const [selectedDateNext, setSelectedDateNext] = React.useState('');
    //const [error, setError] = React.useState();
    const userId = JSON.parse(localStorage.getItem("currentUser")).user.id;
    initialFieldValues.usuarioId = userId;
    const [openFormClientsPopUp, setOpenFormClientsPopUp] = React.useState(false);
    const [openFormIndustriesPopUp, setOpenFormIndustriesPopUp] = React.useState(false);

    UseForm();

    React.useEffect(() => {
        let cancel = false;
    
        const fetchClients = async () => {
          try {
            await getClients(dispatch);
            if (cancel) {
              //setError("Error al cargar los datos");
              setClients([]);
            } else {
             // setError(null);
              var dataClientes = state.clients;
              setClients(dataClientes);
            }
          } catch (error) {
           // setError("Error al cargar los datos");
            setClients([]);
          }
        };
        fetchClients();
        return () => {
          cancel = true;
          setClients([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  // <<REEMPLAZAR POR ESTO SI SE QUIERE RENDERIZAR CADA SEG  }, [dispatch, state.clients, refreshCount]);

    React.useEffect(() => {
        let cancel = false;
    
        const fetchIndustrias = async () => {
          try {
            await getIndustries(dispatch);
            if (cancel) {
            //  setError("Error al cargar los datos");
              setIndustries([]);
            } else {
             // setError(null);
              var dataIndustrias = state.industries;
              setIndustries(dataIndustrias);
            }
          } catch (error) {
           // setError("Error al cargar los datos");
            setIndustries([]);
          }
        };
    
        fetchIndustrias();
    
    
        return () => {
          cancel = true;
          setIndustries([]);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);  // <<REEMPLAZAR POR ESTO SI SE QUIERE RENDERIZAR CADA SEG  }, [dispatch, state.clients, refreshCount]);
    
    
    const validate = (fieldValues = values ) => {
        let temp = { ...errors } 
        if ("titulo" in fieldValues)
        temp.titulo = fieldValues.titulo ? "" : "Este campo es requerido.";
        if ("texto_recordatorio" in fieldValues)
        temp.texto_recordatorio = fieldValues.texto_recordatorio ? "" : "Este campo es requerido.";
        if ("descripcion" in fieldValues)
        temp.descripcion = fieldValues.descripcion ? "" : "Este campo es requerido.";
        if ("clienteId" in fieldValues)
        temp.clienteId = fieldValues.clienteId ? "" : "Este campo es requerido.";
        if ("industriaId" in fieldValues)
        temp.industriaId = fieldValues.industriaId ? "" : "Este campo es requerido.";
        if ("fecha_recordatorio" in fieldValues)
        temp.fecha_recordatorio = fieldValues.fecha_recordatorio ? "" : "Este campo es requerido.";
        if ("potencial" in fieldValues)
          temp.potencial = fieldValues.potencial ? "" : "Este campo es requerido.";
        if ("estado" in fieldValues)
          temp.estado = fieldValues.estado ? "" : "Este campo es requerido.";
            setErrors({
                ...temp
            })
            if(fieldValues === values)
                return Object.values(temp).every(x => x === "")
    }

    
    const {
      values,
      errors,
      setErrors,
      handleInputChange,
    } = UseForm(initialFieldValues, true, validate, initialValues);
  


    const handleSubmit = async e => {
      e.preventDefault();

      // Determine the action text based on the editMode
      const actionText = editMode ? "editada" : "creada";
      
      // Show a swal confirmation dialog
      swal({
        title: "Confirmar envío",
        text: "¿Está seguro de que desea enviar el formulario?",
        icon: "info",
        buttons: ["Cancelar", "Confirmar"],
        dangerMode: false,
      }).then(async (confirm) => {
        if (confirm) {
          if (validate()) {
            if (editMode) {
              editMode(idTask, values);
            } else {
              await addTask(values);
            }
            
            // Show a success swal message when the form is successfully submitted
            swal("OK", `La Tarea fue ${actionText} correctamente!`, "success");
          } else {
            // Show an error swal message when validation fails
            swal("Error", "Favor de cargar todos los campos obligatorios.", "error");
          }
        }
      });
    };

    const handleSubmitClient = async (values) => {
      const newClient = await setClient(values)
      if (newClient.success) {
        swal("Cliente creado correctamente, recargando pagina")
        window.location.reload()
      }
     // setOpenFormClientsPopUp(false)
    };
    const handleSubmitIndustry= async (values) => {
      const newIndustry= await setIndustry(values)
      if (newIndustry.success) {
        swal("Industria creado correctamente, recargando pagina")
        window.location.reload()
      }
      setOpenFormIndustriesPopUp(false)
    };


    
  return (
      <Paper sx={{ background:"#F1F1F2", borderRadius: 1, margin: '5px 5px', maxWidth: 450}}>
          <Form onSubmit={handleSubmit}>
                        <TextField
                                autoComplete="off"
                                size="small"
                                type="text" 
                                variant="outlined"
                                label="Titulo"
                                name="titulo"
                                sx={{background:"#ffffff", borderRadius: 1}}
                                value={values.titulo ? values.titulo : ''}
                                onChange={e => handleInputChange(e,"titulo", null, false)} 
                                error={Boolean(errors.titulo)}
                                helperText={errors.titulo}
                                FormHelperTextProps={{ sx: { color: 'red' } }}
                              />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker 
                label="Seleccione Fecha Recordatorio"
                value={selectedDateNext}
                sx={{background:"#ffffff", borderRadius: 1}}   
                onChange={(newValue) => {
                  setSelectedDateNext(newValue);
                  handleInputChange(null, "fecha_recordatorio", newValue, true);
                }}
                inputFormat="dd-MM-yyyy"  
                renderInput={(params) => <TextField {...params} />}
                disablePast
                ampm={false} // To display the time in 24-hour format
                helperText={errors.fecha_recordatorio}
                error={Boolean(errors.fecha_recordatorio)}
              />
            </LocalizationProvider>



            <Grid container>
                    <Grid item xs={6}>
                        <Box sx={{ width: 240 }}>
                        <FormControl fullWidth error={Boolean(errors.estado)}>
                            <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                            <Select 
                              value={values.estado ? values.estado : ''} 
                              onChange={e => handleInputChange(e, "estado", null, false)} 
                              name="estado"
                              size="small"
                              sx={{background:"#ffffff", borderRadius: 1}}
                            >
                              {estadoOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                            <FormHelperText error color="error">{errors.estado}</FormHelperText>
                          </FormControl>

                        </Box>
                        </Grid> 

                        
                    <Grid item xs={6}>
                        <Box sx={{ width: 240 }}>
                        <FormControl fullWidth error={Boolean(errors.potencial)}>
                        <InputLabel  id="demo-simple-select-label">Potencial</InputLabel>
                            <Select value={values.potencial ? values.potencial : ''} 
                                    onChange={e => handleInputChange(e,"potencial", null, false)} 
                                    name="potencial"
                                    size="small"
                                    sx={{background:"#ffffff", borderRadius: 1}}>
                                    {potencialOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error color="error">{errors.potencial}</FormHelperText>
                        </FormControl>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ width: 240 }}>
                        <FormControl fullWidth error={Boolean(errors.clienteId)}>
                        <InputLabel  id="demo-simple-select-label">Cliente</InputLabel>
                            <Select value={values.clienteId ? values.clienteId : ''} 
                                    onChange={e => handleInputChange(e,"clienteId", null, false)}    
                                    name="clienteId"
                                    size="small"
                                    sx={{background:"#ffffff", borderRadius: 1}}>
                                    {clients.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>
                                        {row.nombre_comercial}
                                        </MenuItem>
                                    ))}
                            </Select>
                            
                            <FormHelperText error color="error">{errors.clienteId}</FormHelperText>
                            
                        </FormControl>
                        
                        </Box>
                        <IconButton 
                            aria-label="refresh"
                            onClick={()=> setOpenFormClientsPopUp(true) }>
                                <AddIcon/>
                      </IconButton>
                    </Grid> 
                    <Grid item xs={6}>
                        <Box sx={{ width: 240 }}>
                        <FormControl fullWidth error={Boolean(errors.industriaId)}>
                        <InputLabel  id="demo-simple-select-label">Industria</InputLabel>
                            <Select value={values.industriaId ? values.industriaId : ''} 
                                    onChange={e => handleInputChange(e,"industriaId", null, false)} 
                                    name="industriaId"
                                    size="small"
                                    sx={{background:"#ffffff", borderRadius: 1}}>
                                    {industries.map((row) => (
                                        <MenuItem key={row.id} value={row.id}>
                                        {row.nombre_comercial}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error color="error">{errors.industriaId}</FormHelperText>
                        </FormControl>
                        </Box>
                        <IconButton 
                            aria-label="refresh"
                            onClick={()=> setOpenFormIndustriesPopUp(true) }>
                                <AddIcon/>
                      </IconButton>    
                    </Grid>   
                    <Grid item xs={6}>
                    
                        <FormControlLabel
                            sx={{ml:"1%"}}
                            control={<Checkbox onChange={e => handleInputChange(e,"llamada", null, false)} 
                            name="llamada"/>}
                            label="Llamada"
                        />
                        <FormControlLabel
                            sx={{ml:"1%"}}
                            control={<Checkbox onChange={e => handleInputChange(e,"visita_presencial", null, false)} 
                            name="visita_presencial"/>}
                            label="Visita Presencial"
                        />                        
                        <FormControlLabel
                            sx={{ml:"1%"}}
                            control={<Checkbox onChange={e => handleInputChange(e,"email", null, false)} 
                            name="email"/>}
                            label="Email"
                        />
                        
                    </Grid>           
            </Grid>
            <TextField
                label="Texto Recordatorio"
                multiline
                minRows={1}
                value={values.texto_recordatorio ? values.texto_recordatorio : ''}
                name="texto_recordatorio"
                onChange={(e) => handleInputChange(e, "texto_recordatorio", null, false)}
                variant="outlined"
                InputProps={{ className: classes.root }}
                error={Boolean(errors.texto_recordatorio)}
                helperText={errors.texto_recordatorio}
                FormHelperTextProps={{ sx: { color: 'red' } }}
            />
            <TextField
                label="Descripcion"
                multiline
                minRows={2}
                value={values.descripcion ? values.descripcion : ''}
                name="descripcion"
                onChange={(e) => handleInputChange(e, "descripcion", null, false)}
                variant="outlined"
                InputProps={{ className: classes.root }}
                error={Boolean(errors.descripcion)}
                helperText={errors.descripcion}
                FormHelperTextProps={{ sx: { color: 'red' } }}
            />
            
            <Button 
              type="submit"
              className="add-task" 
              variant="contained" 
              sx={{
                fontWeight: 'bold',
                border:1,
                borderColor:"black",
                margin: "3%",
                backgroundColor:'#BCBABE',
                color: '#111315',
                "&:hover": {borderColor:"#111315", background:"#BCBABE"}}}
          >{editMode ? 'EDITAR' : 'CREAR'}</Button>
      </Form>
      <PopUp
            title="Ingresar Cliente"
            openPopup={openFormClientsPopUp}
            setOpenPopup={setOpenFormClientsPopUp}>
            <ClientForm addClient={handleSubmitClient} />
          </PopUp>
      <PopUp
            title="Ingresar Industria"
            openPopup={openFormIndustriesPopUp}
            setOpenPopup={setOpenFormIndustriesPopUp}>
            <IndustryForm addIndustry={handleSubmitIndustry} />
          </PopUp>
      </Paper>
  )
}