  import React from "react";
  import { Box } from "@mui/system";
  import { Typography, IconButton, TextField, Button } from "@mui/material";
  import { FormControl, Grid, Select, MenuItem, InputLabel } from '@mui/material';
  import ReusableTable from "../utils/ReusableTable";
  import { useAuthDispatch, useAuthState, getTasks, setTask, getOneTask, getIndustries, getClients, deleteTask, updateTask, getUsers} from "../../context";
  import RefreshIcon from '@mui/icons-material/Refresh';
  import CircularIndeterminate from "../utils/CircularIndeterminate"
  import EmptyPopUp from "../utils/EmptyPopup"
  import PopUp from "../utils/Popup"
  import ReusableButton from "../utils/ReusableButton";
  import TaskForm from "./TareasForm";
  import { capitalizeFirstLetter } from "../utils/Formatters";
  import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
  import { DatePicker } from '@mui/x-date-pickers';
  import { LocalizationProvider } from '@mui/x-date-pickers';
  import dayjs from 'dayjs';
  import swal from "sweetalert";
import { roleOptions } from "../Usuarios/utils";



  function Tareas() {
    const dispatch = useAuthDispatch();
    const [tasks, setTasks] = React.useState([]);
    const [clients, setClients] = React.useState([]);
    const [industries, setIndustries] = React.useState([]);

    const [users, setUsers] = React.useState([]);
    const [oneTask, setOneTask] = React.useState([]);
    const [error, setError] = React.useState();
    const [openEmptyPopup, setOpenEmptyPopup] = React.useState(false);
    const [openFormPopUp, setOpenFormPopUp] = React.useState(false);
    const state = useAuthState();

    const [titleOptions, setTitleOptions] = React.useState([]);
    const [estadoOptions, setEstadoOptions] = React.useState([]);
    const [potencialOptions, setPotencialOptions] = React.useState([]);
    const [usuarioOptions, setUsuarioOptions] = React.useState([]);
    const [fechaActividadOptions, setFechaActividadOptions] = React.useState([]);
    const [fechaRecordatorioOptions, setFechaRecordatorioOptions] = React.useState([]);

    const [titleFilter, setTitleFilter] = React.useState("");
    const [potencialFilter, setPotencialFilter] = React.useState("");
    const [estadoFilter, setEstadoFilter] = React.useState("");
    const [clienteFilter, setClienteFilter] = React.useState("");
    const [industriaFilter, setIndustriaFilter] = React.useState("");
    const [usuarioFilter, setUsuarioFilter] = React.useState("");
    const [fechaActividadFilter, setFechaActividadFilter] = React.useState("");
    const [fechaRecordatorioFilter, setFechaRecordatorioFilter] = React.useState("");
    const [openFormEdit, setopenFormEdit] = React.useState(false);
    const [selectedTask, setSelectedTask] = React.useState(null);
    const [idTask, setIdTask] = React.useState(null)

    
    const handleEdit = (idTask, taskData) => {
      setSelectedTask(taskData);
      setIdTask(idTask)
      setopenFormEdit(true);
    };
    
    
    const handleDelete = async (id) => {
      try {
        swal({
          title: "Esta seguro que quiere eliminar esta tarea?",
          text: "Una vez eliminada, no será posible recuperarlo!",
          icon: "warning",
          buttons: ["Cancelar", "Eliminar"],
          dangerMode: true,
        }).then(async (confirm) => {
          if (confirm) {
            await deleteTask(dispatch, id);
            fetchTasks();
            swal("Tarea eliminado satisfactoriamente!", {
              icon: "success",
            });
          } else {
            swal("Eliminación cancelada!");
          }
        });
      } catch (error) {
        // Handle the error and show an error notification if needed
      }
    };

/////////////////////////////////////////////
    const fetchClients= async () => {
      try {
        setOpenEmptyPopup(true)
        let response = await getClients(dispatch);
        setClients(response.info)
        setOpenEmptyPopup(false)
      }
      catch {
        setError("Error al cargar los datos");
        setClients([])
      }
    }
    React.useEffect(() => {
      fetchClients();
  
      return () => {
        setClients([])
      }
    },[])
////////////////////////////////////////////
 const fetchIndustries= async () => {
  try {
    setOpenEmptyPopup(true)
    let response = await getIndustries(dispatch);
    setIndustries(response.info)
    setOpenEmptyPopup(false)
  }
  catch {
    setError("Error al cargar los datos");
    setIndustries([])
  }
}
React.useEffect(() => {
  fetchIndustries();

  return () => {
    setIndustries([])
  }
},[])
////////////////////////////////////////////
const fetchUsers= async () => {
  try {
    setOpenEmptyPopup(true)
    let response = await getUsers(dispatch);
    setUsers(response.info)
    setOpenEmptyPopup(false)
  }
  catch {
    setError("Error al cargar los datos");
    setIndustries([])
  }
}
React.useEffect(() => {
  fetchUsers();

  return () => {
    setUsers([])
  }
},[])

////////////////////////////////////////////
    const fetchTaskById = async () => {
      try {
        setOpenEmptyPopup(true)
        let response = await getOneTask(1,dispatch);
        setOneTask(response.info)
        setOpenEmptyPopup(false)
      }
      catch {
        setError("Error al cargar los datos");
        setOneTask([])
      }
    }

  React.useEffect(() => {
    fetchTaskById();

    return () => {
      setOneTask([])
    }
  },[])
////////////////////////////////////////////////
    const fetchTasks = async () => {
      try {
        setOpenEmptyPopup(true);
        let response = await getTasks(dispatch);
        setTasks(response.info);
        setOpenEmptyPopup(false);
  // Add this line
      } catch {
        setError("Error al cargar los datos");
        setTasks([]);
      }
    };

    React.useEffect(() => {

      fetchTasks();

      return () => {
        setTasks([]);
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);  // <<REEMPLAZAR POR ESTO SI SE QUIERE RENDERIZAR CADA SEG Y ELIMINAR COMENTARIO DE ARRIBA Q ANULA WARNING  }, [dispatch, state.tasks]);


  React.useEffect(() => {

    // Create a unique list of titles
    const titles = Array.from(new Set(tasks.map(task => task.titulo)));
    setTitleOptions(titles);

    // Create a unique list of estados
    const estadoOptions = Array.from(new Set(tasks.map(task => task.estado)));
    setEstadoOptions(estadoOptions);

    // Create a unique list of usuarios
    const usuarioOptions = Array.from(new Set(tasks.map(task => task.usuarioId)));
    setUsuarioOptions(usuarioOptions);

    // Create a unique list of potenciales
    const potencialOptions = Array.from(new Set(tasks.map(task => task.potencial)));
    setPotencialOptions(potencialOptions);

    // Create a unique list of fecha_actividad values
    const fechaActividadOptions = Array.from(new Set(tasks.map(task => task.uptadedAt)));
    setFechaActividadOptions(fechaActividadOptions);

    // Create a unique list of fecha_recordatorio values
    const fechaRecordatorioOptions = Array.from(new Set(tasks.map(task => task.fecha_recordatorio)));
    setFechaRecordatorioOptions(fechaRecordatorioOptions);

  }, [tasks]);



  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Titulo",
        accessor: "titulo",
        canSort: true,
        type: "String",
      },
      {
        Header: "Estado",
        accessor: "estado",
        canSort: true,
        type: "String",
      },
      {
        Header: "Potencial",
        accessor: "potencial",
        canSort: true,
        type: "String",
      },
      {
        Header: "Fecha Actividad",
        accessor: "updatedAt",
        canSort: true,
        type: "DateTime",
      },
      {
        Header: "Fecha Recordatorio",
        accessor: "fecha_recordatorio",
        canSort: true,
        type: "DateTime",
      },
      {
        Header: "Cliente",
        accessor: "clienteId",
        canSort: true,
        type: "Cliente",
      },
      {
        Header: "Industria",
        accessor: "industriaId",
        canSort: true,
        type: "Industria",
      },
      {
        Header: "Vendedor",
        accessor: "usuarioId",
        canSort: true,
        type: "Vendedor",
      },

      {
        Header: "Llamada",
        accessor: "llamada",
        canSort: true,
        type: "Boolean",
      },
      {
        Header: "Email",
        accessor: "email",
        canSort: true,
        type: "Boolean"
      },
      {
        Header: "Visita Presencial",
        accessor: "visita_presencial",
        canSort: true,
        type: "Boolean",
      },

      {
        Header: "Texto Recordatorio",
        accessor: "texto_recordatorio",
        canSort: true,
        type: "String",
      },
      {
        Header: "Descripción",
        accessor: "descripcion",
        canSort: true,
        type: "String",
      },
      {
        Header: "Subtareas",
        accessor: "subtasks",
        canSort: true,
        type: "Subtasks",
      },
      {
        Header: "Editar",
        accessor: "editar",
        canSort: false,
        type: "EditButton",
      },
      {
        Header: "Eliminar",
        accessor: "eliminar",
        canSort: false,
        type: "DeleteButton",
      },
    ],
    []
  );



    const columnsToShowInitially = ['Titulo', 'Estado', 'Cliente', 'Industria', 'Vendedor','Potencial','Llamada', 'Email', 'Fecha Recordatorio', 'Fecha Actividad','Subtareas']

    const handleRefresh = async (event) => {
      // Reset your filter states here
      setTitleFilter('');
      setPotencialFilter(''); // Set to initial value or empty string
      setEstadoFilter(''); // Set to initial value or empty string
      setFechaActividadFilter(''); // Set to initial value or empty string
      setFechaRecordatorioFilter(''); // Set to initial value or empty string
      setClienteFilter(''); // Set to initial value or empty string
      setIndustriaFilter(''); // Set to initial value or empty string
      setUsuarioFilter(''); // Set to initial value or empty string
    
      fetchTasks();
    };
    

    // const handleFilterSearch = (estado) => {
    //   console.log(estado)
    //   console.log(tasks)
    //   let tempTasks = state.tasks;
    //   if (estado === "") {
    //     // If no title is selected, show all tasks
    //     setTasks(tasks);
    //   } else {
    //     // Filter the tasks array based on the selected title
    //     const filteredTasks = tempTasks.filter((task) => task.estado === estado);
    //     setTasks(filteredTasks);
    //   }
    // };




  const handleTitleFilterChange = (e) => {
    setTitleFilter(e.target.value);
    let filteredTasks = state.tasks;
      filteredTasks = filteredTasks.filter(
        (task) => task.titulo === titleFilter
      );
    
  };
  const handlePotencialFilterChange = (e) => {
    setPotencialFilter(e.target.value);
  };

  const handleEstadoFilterChange = (e) => {
    setEstadoFilter(e.target.value);
  };

  const handleFechaActividadFilterChange = (fecha) => {
    setFechaActividadFilter(fecha);
  };

  const handleFechaRecordatorioFilterChange = (fecha) => {
    setFechaRecordatorioFilter(fecha);
  };

  const handleClienteFilterChange = (e) => {
    setClienteFilter(e.target.value);
  };

  const handleIndustriaFilterChange = (e) => {
    setIndustriaFilter(e.target.value);
  };

  const handleUsuarioFilterChange = (e) => {
    setUsuarioFilter(e.target.value);
  };

  const filterTasks = () => {
    let filteredTasks = state.tasks;

    if (titleFilter) {
      filteredTasks = filteredTasks.filter(
        (task) => task.titulo.toLowerCase().includes(titleFilter.toLowerCase())
      );
    }

    if (potencialFilter) {
      filteredTasks = filteredTasks.filter(
        (task) => task.potencial === potencialFilter
      );
    }

    if (estadoFilter) {
      filteredTasks = filteredTasks.filter(
        (task) => task.estado === estadoFilter
      );
    }

    if (fechaActividadFilter) {
      const formattedDate = dayjs(fechaActividadFilter).format('YYYY-MM-DD')
      filteredTasks = filteredTasks.filter(
        (task) => dayjs(task.updatedAt).format('YYYY-MM-DD') === formattedDate
        
      );
    }

    if (fechaRecordatorioFilter) {
      const formattedDate = dayjs(fechaRecordatorioFilter).format('YYYY-MM-DD')
      filteredTasks = filteredTasks.filter(
        (task) => dayjs(task.fecha_recordatorio).format('YYYY-MM-DD') === formattedDate
      );
    }

    if (clienteFilter) {
      filteredTasks = filteredTasks.filter(
        (task) => task.clienteId === clienteFilter
      );
    }

    if (industriaFilter) {
      filteredTasks = filteredTasks.filter(
        (task) => task.industriaId === industriaFilter
      );
    }

    if (usuarioFilter) {
      filteredTasks = filteredTasks.filter(
        (task) => task.usuarioId === usuarioFilter
      );
    }


    setTasks(filteredTasks);
  };


  const editTask = async (idTask, values) => {
    await updateTask(idTask, values)
    setopenFormEdit(false)
    fetchTasks();
  };

    const handleSubmit = async (values) => {
      await setTask(values)
      setOpenFormPopUp(false)
      fetchTasks();
    };
   
    return (
      <>
      <Box   sx={{maxWidth: '80%'}} sm={{ maxWidth: '80%',paddingTop:10}} >
        {(error !== "") ? ( <div className = "error">{error}</div>) : ""}
            <Grid container spacing={2} pb={10} >
              <Grid item xs={2} sm={2}>
              <Box xs={{width: 250, paddingTop: 2, }} sm={{ width: '100%' }}>
                  <FormControl fullWidth>
                    <TextField
                                autoComplete="off"
                                type="text" 
                                variant="outlined"
                                label="Titulo"
                                name="titulo"
                                sx={{background:"#ffffff", borderRadius: 1}}
                                
                                value={titleFilter}
                                onChange={handleTitleFilterChange} 
                              />
            </FormControl>
          </Box>
        </Grid>
        <Grid item sx={9} sm={2}>
        <Box sx={{ width: '100%'}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label"  sx={{
                  transform: 'translate(0, 3) scale(3)'
                }}>Estado</InputLabel>
              <Select
                value={estadoFilter}
                onChange={handleEstadoFilterChange}
                name="estado"
                sx={{ background: "#ffffff", borderRadius: 1 }}
                MenuProps={{
                  getContentAnchorEl: null,
                  anchorOrigin: {
                    vertical: "center",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "center",
                    horizontal: "left"
                  }
                }}
              >
                {estadoOptions.map((estado, index) => (
                  <MenuItem key={index} value={estado}>
                    {capitalizeFirstLetter(estado)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sx={1.5} sm={2}>
        <Box sx={{ width: '100%', }} sm={{width: '100%'}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Potencial</InputLabel>
              <Select
                value={potencialFilter}
                onChange={handlePotencialFilterChange}
                name="potencial"
                
                sx={{ background: "#ffffff", borderRadius: 1 }}
              >
                {potencialOptions.map((potencial,index) => (
                  <MenuItem key={index} value={potencial}>
                    {capitalizeFirstLetter(potencial)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={2}>
        <Box sx={{ width: '100%',  }} sm={{width: '100%'}}>
            <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                label="Actividad"
                value={fechaActividadFilter || null}
                sx={{background:"#ffffff", borderRadius: 1}}   
                onChange={(newValue) => {
                  setFechaActividadFilter(newValue);
                  handleFechaActividadFilterChange(newValue);
                }}
                inputFormat="dd-MM-yyyy"  
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
              
            </FormControl>
          </Box>
        </Grid>
        <Grid item sm={2}>
        <Box sx={{ width: '100%',}} sm={{width: '100%'}}>
        <FormControl fullWidth>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker 
                label="Recordatorio"
                value={fechaRecordatorioFilter || null}
                sx={{background:"#ffffff", borderRadius: 1}}   
                onChange={(newValue) => {
                  setFechaRecordatorioFilter(newValue);
                  handleFechaRecordatorioFilterChange(newValue);
                }}
                inputFormat="dd-MM-yyyy"  
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
              
            </FormControl>
          </Box>
        </Grid>
        <Grid item  sm={2}>
         <Box sx={{ width: '100%',}} sm={{width: '100%'}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Cliente</InputLabel>
              <Select
                value={clienteFilter}
                onChange={handleClienteFilterChange}
                name="cliente"
            
                sx={{ background: "#ffffff", borderRadius: 1 }}
              >
                {clients.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row.nombre_comercial}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sx={1.5} sm={2}>
          <Box sx={{ width: '100%', }} sm={{width: '100%'}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Industria</InputLabel>
              <Select
                value={industriaFilter}
                onChange={handleIndustriaFilterChange}
                name="industria"
                
                sx={{ background: "#ffffff", borderRadius: 1 }}
              >
                {industries.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                  {row.nombre_comercial}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        <Grid item sx={1.5} sm={2}>
        <Box xs={{ width: '100%', }} sm={{width: '100%'}}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Vendedor</InputLabel>
              <Select
                value={usuarioFilter}
                onChange={handleUsuarioFilterChange}
                name="usuario"
              
                sx={{ background: "#ffffff", borderRadius: 1 }}
              >
                {users.map((row) => (
                  <MenuItem key={row.id} value={row.id}>
                  {row.firstName + ' ' + row.lastName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Grid>
        
        <Grid item sx={{ display: 'flex', justifyContent: 'center' }} >
          <ReusableButton
            text="Aplicar Filtro"
            variant="outlined"
            color="primary"
            onClick={filterTasks} type="button"
            />
        </Grid>
        
      </Grid>
      
            <PopUp
              title="Ingresar Tarea"
              openPopup={openFormPopUp}
              setOpenPopup={setOpenFormPopUp}>
              <TaskForm
              addTask={handleSubmit} />
            </PopUp>
            <PopUp
              title="Editar Tarea"
              openPopup={openFormEdit}
              setOpenPopup={setopenFormEdit}
            >
              <TaskForm
                editMode={editTask}
                idTask={idTask}
                initialValues={selectedTask} // Pass the initial values here
              />
            </PopUp>

            <EmptyPopUp
            title="Cargando..."
            openPopup={openEmptyPopup}
            setOpenPopup={setOpenEmptyPopup}>
          <CircularIndeterminate />
          </EmptyPopUp>
          </Box>

          <Box
            sx={{
              height: '100vh', // Set the height to 100% of the viewport height
              display: 'flex',
              flexDirection: 'column', // Arrange children in a column
            }}
          >
        
      {(error !== "") ? ( <div className = "error">{error}</div>) : ""}
        <Typography variant="h4" sx={{ textAlign:"center", pl:'20p'}}>
          Tareas
        </Typography>
        <Grid container spacing={1}>
      <Grid item xs={0} sx={{ml:0.5}}>
              <IconButton 
                  aria-label="refresh"
                  onClick={() => {
                    handleRefresh();
                  }}>
                      <RefreshIcon/>
            </IconButton>
            
        </Grid>
        <Grid item xs={0} >
          <ReusableButton
            text="nueva"
            variant="outlined"
            color="primary"
            onClick={()=> setOpenFormPopUp(true) } type="button"
            />
        </Grid> 
        <Grid item xs={8} >
        
        </Grid>
        <ReusableTable
            columns={columns}
            data={tasks || []}
            columnsToShowInitially={columnsToShowInitially}
            onEditButtonClick={handleEdit}
            onDeleteButtonClick={handleDelete}
          />   
        </Grid>
      </Box>
    </>
    );
  }
  export default Tareas;