import React, { useState } from 'react';
import { TableCell, TableRow } from '@mui/material';
import { capitalizeFirstLetter } from './Formatters';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import { useAuthDispatch, getUserById, getClientById, getIndustryById } from "../../context";


export const TableDataRow = ({ row, columns, columnsToShow, onEditButtonClick, onDeleteButtonClick, onDownloadFileClick }) => {
  const dispatch = useAuthDispatch();
  const [selectedRow, setSelectedRow] = useState(-1);
  const [oneUser, setOneUser] = React.useState([]);
  const [oneClient, setOneClient] = React.useState([]);
  const [oneIndustry, setOneIndustry] = React.useState([]);




  const fetchUserById = async (id) => {
    try {
      let response = await getUserById(id, dispatch);
      // Extract only firstName and lastName from the response
      const { firstName, lastName } = response.info;
      setOneUser({ firstName, lastName });
    } catch (error) {
      console.error('Error fetching user:', error);
      setOneUser({});
    }
  };

  const fetchIndustryById = async (id) => {
    try {
      let response = await getIndustryById(id, dispatch);
      // Extract only firstName and lastName from the response
      const { nombre_comercial } = response.info;
      setOneIndustry({ nombre_comercial });
    } catch (error) {
      console.error('Error fetching industry:', error);
      setOneIndustry({});
    }
  };

  const fetchClientById = async (id) => {
    try {
      let response = await getClientById(id, dispatch);
      // Extract only firstName and lastName from the response
      const { nombre_comercial } = response.info;
      setOneClient({ nombre_comercial });
    } catch (error) {
      console.error('Error fetching client:', error);
      setOneClient({});
    }
  };

  React.useEffect(() => {
    if (row.usuarioId) {
      fetchUserById(row.usuarioId);
    }
    if (row.clienteId) {
      fetchClientById(row.clienteId);
    }
    if (row.industriaId) {
      fetchIndustryById(row.industriaId);
    }
  }, []);

  const handleRowClick = (index) => {
    setSelectedRow(index === selectedRow ? -1 : index);
  };

  const showDownloadButton = row.adjuntos !== null && row.adjuntos !== '';

  return (
    <>
      <TableRow style={{ backgroundColor: '#d3d3d3', height: 10 }} hover tabIndex={-1} onClick={() => handleRowClick(row.id)}>
        {columns
          .filter((column) => columnsToShow.includes(column.Header))
          .map((column, index) => {
            let cellData = row[column.accessor];
            let cellComponent;
            switch (column.type) {
              case 'Vendedor':
                if (row.usuarioId) {
                  // Note: fetchUserById is an async function, so it returns a Promise.
                  // You can't assign a Promise directly as the cellComponent.
                  cellComponent = `${oneUser.firstName} ${oneUser.lastName}`;
                }
                break;
                case 'Industria':
                  if (row.industriaId) {
                    // Note: fetchUserById is an async function, so it returns a Promise.
                    // You can't assign a Promise directly as the cellComponent.
                    cellComponent = `${oneIndustry.nombre_comercial}`;
                  }
                break;
                case 'Cliente':
                if (row.clienteId) {
                  // Note: fetchUserById is an async function, so it returns a Promise.
                  // You can't assign a Promise directly as the cellComponent.
                  cellComponent = `${oneClient.nombre_comercial}`;
                }
                break;
              case 'EditButton':
                cellComponent = (
                  <IconButton aria-label="edit" onClick={() => onEditButtonClick(row.id, row)}>
                    <EditIcon />
                  </IconButton>
                );
                break;
              case 'DeleteButton':
                cellComponent = (
                  <IconButton aria-label="delete" onClick={() => onDeleteButtonClick(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                );
                break;
              case 'DateTime':
                cellComponent = dayjs(cellData).format('DD/MM/YYYY HH:mm:ss A');
                break;
              case 'Date':
                cellComponent = dayjs(cellData).format('DD/MM/YYYY');
                break;
              case 'Boolean':
                cellComponent = cellData ? 'Si' : 'No';
                break;
              case 'String':
                cellComponent = capitalizeFirstLetter(cellData);
                break;
              case 'Subtasks':
                cellComponent = (
                  <Link to={`/subtareas/${row.id}`}>
                    Ver Subtareas
                  </Link>
                );
                break;
              default:
                cellComponent = cellData;
            }

            if (column.accessor === "idArchivo" && row.idArchivo !== '') {
              cellComponent = showDownloadButton ? (
                <IconButton
                  aria-label="download"
                  onClick={() => onDownloadFileClick(row)}
                >
                  <DownloadIcon />
                </IconButton>
              ) : null;
            }
            

            return (
              <TableCell key={index} sx={{ color: '#111315', width: 5, whiteSpace: 'nowrap', textAlign: 'center' }}>
                {cellComponent}
              </TableCell>
            );
          })}
      </TableRow>
      {selectedRow === row.id && (
        <>
          {columns.map((column, index) => {
            if (column.hide) {
              return null; // skip the column if it's hidden
            }

            let cellData = row[column.accessor];
            let columnName = column.Header;
            let cellComponent;
            switch (column.type) {
              case 'EditButton':
                cellComponent = (
                  <IconButton aria-label="edit" onClick={() => onEditButtonClick(row.id, row)}>
                    <EditIcon />
                  </IconButton>
                );
                break;
              case 'DeleteButton':
                cellComponent = (
                  <IconButton aria-label="delete" onClick={() => onDeleteButtonClick(row.id)}>
                    <DeleteIcon />
                  </IconButton>
                );
                break;
              case 'DateTime':
                cellComponent = dayjs(cellData).format('DD/MM/YYYY HH:mm:ss A');
                break;
              case 'Date':
                cellComponent = dayjs(cellData).format('DD/MM/YYYY');
                break;
              case 'Boolean':
                cellComponent = cellData ? 'Si' : 'No';
                break;
              case 'String':
                cellComponent = capitalizeFirstLetter(cellData);
                break;
              case 'Subtasks':
                cellComponent = (
                  <Link to={`/subtareas/${row.id}`}>
                    Ver Subtareas
                  </Link>
                );
                break;
              default:
                cellComponent = cellData;
            }

            return (
              <TableRow key={index}>
                <TableCell sx={{ fontWeight: 'bold' }}>{columnName}:</TableCell>
                <TableCell sx={{ color: '#111315', width: 5, whiteSpace: 'nowrap', textAlign: 'center' }}>
                  {cellComponent}
                </TableCell>
              </TableRow>
            );
          })}
        </>
      )}
    </>
  );
};
