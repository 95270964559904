import React from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  MenuItem,
  Menu,
  Avatar,
  Drawer,
  Box, // Add this line to import the Box component
  Grid,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardBackspaceIcon from "@mui/icons-material/ArrowBackIos";
import { useHistory } from "react-router-dom";
import { logout, useAuthDispatch, useAuthState } from "../context";
import { roleOptions } from "./Usuarios/utils";
import iconotareas from "../assets/tarea.png";
import iconoclientes from "../assets/cliente.png";
import iconoindustrias from "../assets/almacen.png";
import iconoadmin from "../assets/folder.png";

import "./MenuAppBar.css";

// Rest of your code remains unchanged


export default function MenuAppBar({ handleBtnClick }) {
  const dispatch = useAuthDispatch();
  const state = useAuthState();
  const userName = state?.user?.username;
  const role = state?.user?.role;
  const avatar = state?.imageLink || null;

  let history = useHistory();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);

  const handleButtonClick = () => {
    setIsDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setIsDrawerOpen(false);
  };

  const handleNavigation = (path) => {
    history.push(path);
    setIsDrawerOpen(false);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignOut = async () => {
    await logout(dispatch);
    setAnchorEl(null);
  };

  return (
    <>
      <Drawer
        open={isDrawerOpen}
        onClose={handleCloseDrawer}
      >
        <Box
          p={3}
          width="300px"
          height="100%"
          textAlign="baseline"
          sx={{ bgcolor: "#ffffff", display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
          <IconButton
            size="large"
            color="inherit"
            aria-label="menu"
            sx={{
              "& svg": {
                fontSize: "30px",
                color: "#323338",
                fill: "#323338",
              },
              marginBottom: "20px",
            }}
            onClick={handleCloseDrawer}
          >
            <KeyboardBackspaceIcon />
          </IconButton>

          {/** Tareas Button */}
          <Grid
            container
            alignItems="center"
            spacing={2}
            onClick={() => handleNavigation("/tareas")}
            sx={{ cursor: "pointer", marginBottom: "10px" }}
          >
            <Grid item>
              <img src={iconotareas} height="35px" alt="Task" />
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" sx={{ fontSize: '28px', fontWeight: '600', color: '#000000' }}>
                Tareas
              </Typography>
            </Grid>
          </Grid>

          {/** Clientes Button */}
          {role === roleOptions[0].value || role === roleOptions[1].value ? (
            <Grid
              container
              alignItems="center"
              spacing={2}
              onClick={() => handleNavigation("/clientes")}
              sx={{ cursor: "pointer", marginBottom: "10px" }}
            >
              <Grid item>
                <img src={iconoclientes} height="35px" alt="Client" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" sx={{ fontSize: '28px', fontWeight: '600', color: '#000000' }}>
                  Clientes
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {/** Industrias Button */}
          {role === roleOptions[0].value || role === roleOptions[1].value ? (
            <Grid
              container
              alignItems="center"
              spacing={2}
              onClick={() => handleNavigation("/industrias")}
              sx={{ cursor: "pointer", marginBottom: "10px" }}
            >
              <Grid item>
                <img src={iconoindustrias} height="35px" alt="Industry" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" sx={{ fontSize: '28px', fontWeight: '600', color: '#000000' }}>
                  Industrias
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {/** Administracion Button */}
          {role === roleOptions[0].value ? (
            <Grid
              container
              alignItems="center"
              spacing={2}
              onClick={() => handleNavigation("/administracion")}
              sx={{ cursor: "pointer", marginBottom: "10px" }}
            >
              <Grid item>
                <img src={iconoadmin} height="35px" alt="Admin" />
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" sx={{ fontSize: '28px', fontWeight: '600', color: '#000000' }}>
                  Administracion
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          {/* ... rest of the code remains unchanged ... */}
        </Box>
      </Drawer>


      <Box>
      <AppBar
          elevation={2}
          sx={{
            flexGrow: 1,
            height: '7vh',  // Adjust the height as needed
            width: '100%',
            bgcolor: "#323338",
            padding: "0.5% 0",
          }}
        >
          <Toolbar>
            <div onClick={handleButtonClick}>
              <IconButton
                size="large"
                color="inherit"
                aria-label="menu"
                sx={{
                  "& svg": {
                    fontSize: "35px",
                    color: "#ffffff",
                    fill: "#ffffff",
                  },
                }}
              >
                <MenuIcon />
              </IconButton>
            </div>

            <div style={{ width: "85%" }} className="avatar-container">
              <Typography
                variant="subtitle1"
                sx={{
                  fontSize: "25px",
                  fontFamily: "Poppins",
                  color: "#ffffff",
                  float: "right",
                }}
              >
                {userName}
              </Typography>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                color="inherit"
                sx={{
                  position: "absolute",
                  right: "1%",
                  "& svg": {
                    fontSize: "35px",
                    color: "black",
                    fill: "black",
                  },
                  mr: 1,
                }}
              >
                <Avatar src={avatar} alt="PF" />
              </IconButton>
              <Menu
                id="account-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={handleSignOut}>Cerrar Sesion</MenuItem>
              </Menu>
            </div>
          </Toolbar>
        </AppBar>
      </Box>
    </>
  );
}
