import React from 'react';
import { Button } from '@mui/material';

const ReusableButton = ({ text, variant, color, onClick, disabled, type }) => {
  return (
    <Button 
    type={type}
    
    sx={{
    fontSize: "15px",
    fontWeight: 'bold', 
    pl:6,
    pr:6,
    pt:1,
    pb:1,
    border: 1, 
    backgroundColor: '#ffffff', 
    color: '#111315',
     "&:hover": {borderColor:"#ffffff",  color: '#ffffff',backgroundColor:"#111315"} }}
    variant={variant} color={color} onClick={onClick} disabled={disabled}>
      {text}
    </Button>
  );
};

export default ReusableButton;