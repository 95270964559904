import React from 'react';
import { FormControl, Grid, TextField, Paper, Button, Select, MenuItem, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { UseForm, Form } from '../utils/UseForm';
import { useAuthDispatch, useAuthState } from "../../context";
import { FormHelperText } from '@material-ui/core';
import swal from "sweetalert";

// const provinciasData = [
//     { id: "02", nombre: "Ciudad Autónoma de Buenos Aires", nombre_completo: "Ciudad Autónoma de Buenos Aires" },
//     { id: "06", nombre: "Buenos Aires", nombre_completo: "Provincia de Buenos Aires" },
//     { id: "54", nombre: "Misiones", nombre_completo: "Provincia de Misiones" },
//     { id: "74", nombre: "San Luis", nombre_completo: "Provincia de San Luis" },
//     { id: "70", nombre: "San Juan", nombre_completo: "Provincia de San Juan" },
//     { id: "30", nombre: "Entre Ríos", nombre_completo: "Provincia de Entre Ríos" },
//     { id: "78", nombre: "Santa Cruz", nombre_completo: "Provincia de Santa Cruz" },
//     { id: "62", nombre: "Río Negro", nombre_completo: "Provincia de Río Negro" },
//     { id: "26", nombre: "Chubut", nombre_completo: "Provincia del Chubut" },
//     { id: "14", nombre: "Córdoba", nombre_completo: "Provincia de Córdoba" },
//     { id: "50", nombre: "Mendoza", nombre_completo: "Provincia de Mendoza" },
//     { id: "46", nombre: "La Rioja", nombre_completo: "Provincia de La Rioja" },
//     { id: "10", nombre: "Catamarca", nombre_completo: "Provincia de Catamarca" },
//     { id: "42", nombre: "La Pampa", nombre_completo: "Provincia de La Pampa" },
//     { id: "86", nombre: "Santiago del Estero", nombre_completo: "Provincia de Santiago del Estero" },
//     { id: "18", nombre: "Corrientes", nombre_completo: "Provincia de Corrientes" },
//     { id: "82", nombre: "Santa Fe", nombre_completo: "Provincia de Santa Fe" },
//     { id: "90", nombre: "Tucumán", nombre_completo: "Provincia de Tucumán" },
//     { id: "58", nombre: "Neuquén", nombre_completo: "Provincia del Neuquén" },
//     { id: "66", nombre: "Salta", nombre_completo: "Provincia de Salta" },
//     { id: "22", nombre: "Chaco", nombre_completo: "Provincia del Chaco" },
//     { id: "34", nombre: "Formosa", nombre_completo: "Provincia de Formosa" },
//     { id: "38", nombre: "Jujuy", nombre_completo: "Provincia de Jujuy" },
//     { id: "94", nombre: "Tierra del Fuego", nombre_completo: "Provincia de Tierra del Fuego, Antártida e Islas del Atlántico Sur" },
//   ];

  
const initialFieldValues = {
    cuit_cuil: "",
    potencial: "",
    estado: "",
    domicilio: "",
    ciudad: "",
    // provincia:"",
    nombre_comercial : "",
    codigo_transporte : null,
    codigo_zona : null,
    codigo_postal : null,
    telefono : null,
    email : "",
    contacto : "",
    razon_social : "",
    notas : "",
    fecha_inicio : "",
    sitio_web : "",
    rubro : "",
    direccion_fiscal : ""
}

const potencialOptions = [
    { value: "alto", label: "Alto" },
    { value: "medio", label: "Medio" },
    { value: "bajo", label: "Bajo" },
  ]
const estadoOptions = [
    { value: "prospecto", label: "Prospecto" },
    { value: "cliente", label: "Cliente" },
]


export default function IndustryForm (props) {

    const {initialValues, addIndustry, editMode, idIndustry} = props
    const state = useAuthState();
    const dispatch = useAuthDispatch();
    //const [error, setError] = React.useState();
    const [selectedDate, setSelectedDate] = React.useState('');
    UseForm();

    
    
    const validate = (fieldValues = values ) => {
        let temp = { ...errors } 
        if ('cuit_cuil' in fieldValues)
            temp.cuit_cuil = fieldValues.cuit_cuil ? "" : "Debe proporcionar este campo."
        if ('potencial' in fieldValues)
            temp.potencial = fieldValues.potencial ? "" : "Debe proporcionar este campo."
        if ('estado' in fieldValues)
            temp.estado = fieldValues.estado ? "" : "Debe proporcionar este campo."
        if ('domicilio' in fieldValues)
            temp.domicilio = fieldValues.domicilio ? "" : "Debe proporcionar este campo."
        if ('ciudad' in fieldValues)
            temp.ciudad = fieldValues.ciudad ? "" : "Debe proporcionar este campo."
        // if ('provincia' in fieldValues)
        //     temp.provincia = fieldValues.provincia ? "" : "Debe proporcionar este campo."
        if ('nombre_comercial' in fieldValues)
            temp.nombre_comercial = fieldValues.nombre_comercial ? "" : "Debe proporcionar este campo."
        if ('codigo_transporte' in fieldValues)
            temp.codigo_transporte = fieldValues.codigo_transporte ? "" : "Debe proporcionar este campo."
        if ('codigo_zona' in fieldValues)
            temp.codigo_zona = fieldValues.codigo_zona ? "" : "Debe proporcionar este campo."
        if ('codigo_postal' in fieldValues)
            temp.codigo_postal = fieldValues.codigo_postal ? "" : "Debe proporcionar este campo."
        if ('telefono' in fieldValues)
            temp.telefono = fieldValues.telefono ? "" : "Debe proporcionar este campo."
        if ('email' in fieldValues)
            temp.email = fieldValues.email ? "" : "Debe proporcionar este campo."
        // if ('contacto' in fieldValues)
        //     temp.contacto = fieldValues.contacto ? "" : "Debe proporcionar este campo."
        if ('razon_social' in fieldValues)
            temp.razon_social = fieldValues.razon_social ? "" : "Debe proporcionar este campo."
        // if ('notas' in fieldValues)
        //     temp.notas = fieldValues.notas ? "" : "Debe proporcionar este campo."
        // if ('fecha_inicio' in fieldValues)
        //     temp.fecha_inicio = fieldValues.fecha_inicio ? "" : "Debe proporcionar este campo."
        // if ('sitio_web' in fieldValues)
        //     temp.sitio_web = fieldValues.sitio_web ? "" : "Debe proporcionar este campo."
        if ('rubro' in fieldValues)
            temp.rubro = fieldValues.rubro ? "" : "Debe proporcionar este campo."
        if ('direccion_fiscal' in fieldValues)
            temp.direccion_fiscal = fieldValues.direccion_fiscal ? "" : "Debe proporcionar este campo."
            setErrors({
                ...temp
            })
            if(fieldValues === values)
                return Object.values(temp).every(x => x === "")
    }

    const {
        values,
        errors,
        setErrors,
        handleInputChange,
      } = UseForm(initialFieldValues, true, validate, initialValues);

   



      const handleSubmit = e => {
        e.preventDefault();
        
        // Determine the action text based on the editMode
        const actionText = editMode ? "editada" : "creada";

        // Show a swal confirmation dialog
        swal({
          title: "Confirmar envío",
          text: "¿Está seguro de que desea enviar el formulario?",
          icon: "info",
          buttons: ["Cancelar", "Confirmar"],
          dangerMode: false,
        }).then(async (confirm) => {
          if (confirm) {
            if (validate()) {
              if (editMode) {
                editMode(idIndustry, values)
              } else {
                addIndustry(values);
              }
              
              // Show a success swal message when the form is successfully submitted
              swal("OK", `La industria fue ${actionText} correctamente!`, "success");
            } else {
              // Show an error swal message when validation fails
              swal("Error", "Favor de cargar todos los campos obligatorios.", "error");
            }
          }
        });
      };

    
  return (
      <Paper sx={{ backgroundColor:"#ffffff", borderRadius: 1, boxShadow:0, margin: '5px 5px', maxWidth: 450}}>
          <Form onSubmit={handleSubmit}>

            <Grid container>
            <TextField
                                autoComplete="off"
                                size="small"
                                type="text" 
                                variant="outlined"
                                label="CUIT-CUIL"
                                name="cuit_cuil"
                                sx={{background:"#ffffff", borderRadius: 1}}
                                value={values.cuit_cuil ? values.cuit_cuil : ''}
                                onChange={e => handleInputChange(e,"cuit_cuil", null, false)} 
                                error={Boolean(errors.cuit_cuil)}
                                helperText={errors.cuit_cuil}
                                FormHelperTextProps={{ sx: { color: 'red' } }}
                            />
                    <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Nombre Comercial"
                            name="nombre_comercial"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.nombre_comercial ? values.nombre_comercial : ''}
                            onChange={e => handleInputChange(e,"nombre_comercial", null, false)} 
                            error={Boolean(errors.nombre_comercial)}
                            helperText={errors.nombre_comercial}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
                    <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Razon Social"
                            name="razon_social"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.razon_social ? values.razon_social : ''}
                            onChange={e => handleInputChange(e,"razon_social", null, false)} 
                            error={Boolean(errors.razon_social)}
                            helperText={errors.razon_social}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
                    <Grid item xs={6}>
                        <Box sx={{ width: 240 }}>
                        <FormControl fullWidth error={Boolean(errors.estado)}>
                        <InputLabel  id="demo-simple-select-label"> Estado</InputLabel>
                            <Select value={values.estado ? values.estado : ''} 
                                    onChange={e => handleInputChange(e,"estado", null, false)} 
                                    name="estado"
                                    size="small"
                                    sx={{background:"#ffffff", borderRadius: 1}}>
                                    {estadoOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error color="error">{errors.estado}</FormHelperText>
                        </FormControl>
                        </Box>
                        </Grid> 
                    <Grid item xs={6}>
                        <Box sx={{ width: 240 }}>
                        <FormControl fullWidth error={Boolean(errors.potencial)}>
                        <InputLabel id="demo-simple-select-label"> Potencial</InputLabel>
                            <Select value={values.potencial ? values.potencial : ''} 
                                    onChange={e => handleInputChange(e,"potencial", null, false)} 
                                    name="potencial"
                                    size="small"
                                    sx={{background:"#ffffff", borderRadius: 1}}>
                                    {potencialOptions.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                        {option.label}
                                        </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error color="error">{errors.potencial}</FormHelperText>
                        </FormControl>
                        </Box>
                    </Grid>           
            </Grid>
            <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Domicilio"
                            name="domicilio"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.domicilio ? values.domicilio : ''}
                            onChange={e => handleInputChange(e,"domicilio", null, false)} 
                            error={Boolean(errors.domicilio)}
                            helperText={errors.domicilio}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />

            <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Ciudad"
                            name="ciudad"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.ciudad ? values.ciudad : ''}
                            onChange={e => handleInputChange(e,"ciudad", null, false)} 
                            error={Boolean(errors.ciudad)}
                            helperText={errors.ciudad}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
                        {/* <Grid item xs={6}>
                        <Box sx={{ width: 240 }}>
                        <FormControl fullWidth error={Boolean(errors.provincia)}>
                        <InputLabel id="demo-simple-select-label"> Provincia</InputLabel>
                            <Select value={values.provincia ? values.provincia : ''}
                                    onChange={e => handleInputChange(e,"provincia", null, false)} 
                                    name="Provincia"
                                    size="small"
                                    sx={{background:"#ffffff", borderRadius: 1}}>
                                    {provinciasData.map((value) => (
                                        <MenuItem key={value.id} value={value.nombre}>
                                        {value.nombre}
                                    </MenuItem>
                                    ))}
                            </Select>
                            <FormHelperText error color="error">{errors.provincia}</FormHelperText>
                        </FormControl>
                        </Box>
                    </Grid>   */}

            <TextField
                            autoComplete="off"
                            size="small"
                            type="number" 
                            variant="outlined"
                            label="Codigo Transporte"
                            name="codigo_transporte"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.codigo_transporte ? values.codigo_transporte : ''}
                            onChange={e => handleInputChange(e,"codigo_transporte", null, false)} 
                            error={Boolean(errors.codigo_transporte)}
                            helperText={errors.codigo_transporte}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="number" 
                            variant="outlined"
                            label="Codigo Zona"
                            name="codigo_zona"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.codigo_zona ? values.codigo_zona : ''}
                            onChange={e => handleInputChange(e,"codigo_zona", null, false)} 
                            error={Boolean(errors.codigo_zona)}
                            helperText={errors.codigo_zona}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="number" 
                            variant="outlined"
                            label="Codigo Postal"
                            name="codigo_postal"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.codigo_postal ? values.codigo_postal : ''}
                            onChange={e => handleInputChange(e,"codigo_postal", null, false)} 
                            error={Boolean(errors.codigo_postal)}
                            helperText={errors.codigo_postal}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="number" 
                            variant="outlined"
                            label="Numero Telefonico"
                            name="telefono"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.telefono ? values.telefono : ''}
                            onChange={e => handleInputChange(e,"telefono", null, false)} 
                            error={Boolean(errors.telefono)}
                            helperText={errors.telefono}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="email" 
                            variant="outlined"
                            label="Correo Electronico"
                            name="email"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.email ? values.email : ''}
                            onChange={e => handleInputChange(e,"email", null, false)} 
                            error={Boolean(errors.email)}
                            helperText={errors.email}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Contacto"
                            name="contacto"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.contacto ? values.contacto : ''}
                            onChange={e => handleInputChange(e,"contacto", null, false)} 
                            error={Boolean(errors.contacto)}
                            helperText={errors.contacto}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            
            <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Notas"
                            name="notas"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.notas ? values.notas : ''}
                            onChange={e => handleInputChange(e,"notas", null, false)} 
                            error={Boolean(errors.notas)}
                            helperText={errors.notas}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Sitio Web"
                            name="sitio_web"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.sitio_web ? values.sitio_web : ''}
                            onChange={e => handleInputChange(e,"sitio_web", null, false)} 
                            error={Boolean(errors.sitio_web)}
                            helperText={errors.sitio_web}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Rubro"
                            name="rubro"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.rubro ? values.rubro : ''}
                            onChange={e => handleInputChange(e,"rubro", null, false)} 
                            error={Boolean(errors.rubro)}
                            helperText={errors.rubro}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            <TextField
                            autoComplete="off"
                            size="small"
                            type="text" 
                            variant="outlined"
                            label="Dirección Fiscal"
                            name="direccion_fiscal"
                            sx={{background:"#ffffff", borderRadius: 1}}
                            value={values.direccion_fiscal ? values.direccion_fiscal : ''}
                            onChange={e => handleInputChange(e,"direccion_fiscal", null, false)} 
                            error={Boolean(errors.direccion_fiscal)}
                            helperText={errors.direccion_fiscal}
                            FormHelperTextProps={{ sx: { color: 'red' } }}
                        />
            
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker 
                                label="Seleccione Aniversario"
                                value={selectedDate}   
                                onChange={(newValue) => {
                                    setSelectedDate(newValue)
                                    handleInputChange(null,"fecha_inicio", newValue, true);
                                }}
                                format="DD/MM/YYYY"
                                renderInput={(params) => <TextField {...params} helperText={null} />}
                                sx={{background:"#ffffff", borderRadius: 1}}
                                helperText={errors.fecha_inicio}
                                error={Boolean(errors.fecha_inicio)}
                                    />
            </LocalizationProvider>
            <Button 
              type="submit"
              className="add-task" 
              variant="contained" 
              sx={{
                fontWeight: 'bold',
                border:1,
                borderColor:"black",
                margin: "3%",
                backgroundColor: '#ffffff', 
                color: '#111315',
                 "&:hover": {borderColor:"#ffffff",  color: '#ffffff',backgroundColor:"#111315"}}}
          >{editMode ? 'EDITAR' : 'CREAR'}</Button>
      </Form>
      </Paper>
  )
}