import React from "react";
import MenuAppBar from "../../components/MenuAppBar";
import "./ClientesPage.css";
import Grid from '@mui/material/Grid';
import Clientes from "../../components/Clientes/Clientes";
import { Box } from "@mui/system";

function ClientesPage() {
 //console.log("token", userToken);
  const [sidebarVisible, setSidebarVisible] = React.useState(false);

  const toggleSidebar = () => {
    if (!sidebarVisible) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }
  };

  return (
    <>
      <MenuAppBar handleBtnClick={toggleSidebar} />
    <Box  sx={{marginTop:"5%", width:'100%', position: 'relative', top: '64px' }}>
    <Grid  container justifyContent="center" spacing={2}>
        <Clientes />
      </Grid>
    </Box>
    </>
  );
}

export default ClientesPage;