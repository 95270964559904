import { ROOT_URL } from "./Config/constants";


export async function triggerNotification() {

 
  try {
    const currentUser = JSON.parse(localStorage.getItem("currentUser")).user;
    const body = {
      userId: currentUser.id,
    };

    await fetch(`${ROOT_URL}/api/send-notification`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });
    //console.log('Notification triggered successfully');
  } catch (error) {
    console.error('Failed to trigger notification:', error);
  }
}