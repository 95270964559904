import HomePage from "../pages/HomePage";
import LoginPage from "../pages/Login/LoginPage";
import TareasPage from "../pages/Tareas/TareasPage";
import ClientesPage from "../pages/Clientes/ClientesPage";
import IndustriasPage from "../pages/Industrias/IndustriasPage";
import AdminPage from "../pages/Admin/AdminPage";
import SubTareasPage from "../pages/Subtareas/SubTareasPage";


const routes = [
  {
    path: "/home",
    component: HomePage,
    isPrivate: true,
  },
  {
    path: "/tareas",
    component: TareasPage,
    isPrivate: true,
  },
  {
    path: "/industrias",
    component: IndustriasPage,
    isPrivate: true,
  },
  {
    path: "/clientes",
    component: ClientesPage,
    isPrivate: true,
  },
  {
    path: "/administracion",
    component: AdminPage,
    isPrivate: true,
  },
  {
    path: "/subtareas/:id",
    component: SubTareasPage,
    isPrivate: true,
  },
  {
    path: "/",
    component: LoginPage,
    isPrivate: false,
  },
];

export default routes;