import React from "react";
import MenuAppBar from "../../components/MenuAppBar";
import "./SubTareasPage.css";
import Grid from '@mui/material/Grid';
import { Box } from "@mui/system";
import Subtareas from "../../components/Subtareas/Subtareas";

function SubTareasPage() {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);

  const toggleSidebar = () => {
    if (!sidebarVisible) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }
  };

  return (
    <>
    <MenuAppBar handleBtnClick={toggleSidebar} />
    <Box sx={{marginTop:"5%", position: 'relative', top: '64px' }}>
    <Grid container justifyContent="center" spacing={3}>
        <Subtareas />
      </Grid>
    </Box>
    </>
  );
}

export default SubTareasPage;
