import { ROOT_URL } from "../Config/constants";
import axios from "axios";



export async function downloadFile(fileId, fileName) {
  console.log(fileId)
  console.log(fileName)
  try {
    const response = await fetch(`${ROOT_URL}/api/download/${fileId}/${fileName}`);
    console.log("response", response)
    const blob = await response.blob();
    console.log(blob)

    // Create a temporary anchor element
    const downloadLink = document.createElement('a');
    // Set the href to a Blob URL representing the file data
    downloadLink.href = window.URL.createObjectURL(blob);
    // Set the download attribute to provide the desired file name
    downloadLink.setAttribute('download', fileName);
    console.log(downloadLink)
    // Append the anchor element to the document
    document.body.appendChild(downloadLink);
    // Trigger a click on the anchor to start the download
    downloadLink.click();
    // Remove the anchor from the document
    document.body.removeChild(downloadLink);
  } catch (error) {
    console.error('Error downloading file:', error);
    // Handle the error as needed
  }
}


export async function uploadFile(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = async (event) => {
      try {
        const buffer = event.target.result;
        const response = await sendFileToServer(file.name, file.type, buffer);
        resolve(response);
      } catch (error) {
        reject(error);
      }
    };

    reader.readAsArrayBuffer(file);
  });
}

async function sendFileToServer(filename, mimetype, buffer) {
  // Create the fileBody object and send it to the server
  const fileBody = {
    filename,
    mimetype,
    buffer: Array.from(new Uint8Array(buffer)),
  };

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      token: JSON.parse(localStorage.getItem('currentUser')).token,
    },
    body: JSON.stringify(fileBody),
  };

  const response = await fetch(`${ROOT_URL}/api/upload-file`, requestOptions);
  if (response.ok) {
    return await response.text();
  } else {
    throw new Error('Error uploading file');
  }
}





export async function unsubscribeUserFromNotifications() {
  try {
    const currentUser = JSON.parse(localStorage.getItem("currentUser")).user;

    if (!currentUser) {
      console.log('User is not logged in. Skipping unsubscription.');
      return;
    }

    // Send a POST request to your server to unsubscribe the user
    const response = await fetch(`${ROOT_URL}/api/notification/unsubscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ userId: currentUser.id }),
    });

    if (response.status === 200) {
      const data = await response.json();
      console.log('User unsubscribed from notifications:', data);
    } else if (response.status === 404) {
      const data = await response.json();
      console.log('Subscription not found:', data);
    } else {
      console.error('Error unsubscribing from notifications. Status:', response.status);
    }
  } catch (error) {
    console.error('Error unsubscribing from notifications:', error);
  }
}





export async function subscribeUserToNotifications() {
  try {
    const serviceWorkerRegistration = await navigator.serviceWorker.ready;
    
    // Check if the user is logged in before subscribing
    const currentUser = JSON.parse(localStorage.getItem("currentUser"));
    if (!currentUser) {
      console.log('User is not logged in. Skipping subscription.');
      return;
      
    }

    const subscription = await serviceWorkerRegistration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: 'BMZVEzV4Jr7JwWPXOqo_rMjxdVU5chAXFuFwzJGLlK4g2qhtty5W9PA0Ya0TnDwBrCsYPcq3jNlPswJSVfrIZr0',
    });


    // Send the subscription details to your backend
    await sendSubscriptionToBackend(subscription);

    console.log('User subscribed to notifications!');
  } catch (error) {
    console.error('Error subscribing to notifications:', error);
  }
}


export async function sendSubscriptionToBackend(subscription) {
  try {
    const currentUser = JSON.parse(localStorage.getItem("currentUser")).user;

    if (!currentUser) {
      console.log('User is not logged in. Skipping subscription.');
      return;
    }

    const body = {
      userId: currentUser.id,
      subscription: subscription,
    };

    const response = await fetch(`${ROOT_URL}/api/notification/subscribe`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();
    console.log('Subscription sent to backend:', data);
  } catch (error) {
    console.error('Error sending subscription to backend:', error);
  }
}



export async function loginUser(dispatch, loginPayload) {
  try {
    dispatch({ type: "REQUEST_LOGIN" });

    const response = await axios.post(`${ROOT_URL}/api/login`, loginPayload);
    
    const data = response.data;

    if (response.data.ok) { 
      console.log("data",data)
      if (data.info) {
        localStorage.setItem("currentUser", JSON.stringify(data.info));
        dispatch({ type: "LOGIN_SUCCESS", payload: data.info });

        // Subscribe the user to push notifications
        await subscribeUserToNotifications();

        return data;
      }
    }

    dispatch({ type: "LOGIN_ERROR", error: data.err });

    return;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}




export async function logout(dispatch) {
  // Unsubscribe the user from push notifications
  
  await unsubscribeUserFromNotifications();

  // Remove the user from local storage
  localStorage.removeItem("currentUser");

  // Dispatch the logout action
  dispatch({ type: "LOGOUT" });
}




export const userRegister = async (payload) => {
  const requestOptions = {
    method : "POST",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
    body: JSON.stringify(payload)
  }
  try {
    const response = await fetch(`${ROOT_URL}/api/register`, requestOptions)
    const data = await response.json()
    return data

  } catch(error){
    console.log(error)
  }
}


export async function getUsers(dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/users`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_USERS", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error + "try catch actions getUsers");
  }
}


export async function getIdUser(dispatch, id) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/users`, requestOptions);
    const data = await response.json();
    const userId = await data.info.filter(u => u._id === id)
    dispatch({ type: "GET_ID_USER", payload: userId });
    return userId;
  } catch (error) {
    dispatch({ type: "USERID_ERROR", error: error });
  }
}

export async function logicalDeleteUser(dispatch, id) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/users/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

/////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////////DELETE FUNCTIONS/////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////

export async function deleteUser(dispatch, id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/users/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

export async function deleteTask(dispatch, id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/tareas/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}


export async function deleteIndustry(dispatch, id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/industrias/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

export async function deleteSubtask(dispatch, id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/subtareas/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}

export async function deleteClient(dispatch, id) {
  const requestOptions = {
    method: "DELETE",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/clientes/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
  }
}
////////////////////////////////////////////////////////////////////////////////////////////




export async function getTasks(dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/tareas`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_TAREAS", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error + "try catch actions getTasks");
  }
}


export async function getClients(dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/clientes`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_CLIENTES", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error + "try catch actions getTasks");
  }
}

export async function getIndustries(dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/industrias`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_INDUSTRIAS", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error + "try catch actions getTasks");
  }
}


export const setTask = async ( payload) => {
  const requestOptions = {
    method : "POST",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
    body: JSON.stringify(payload)
  }
  try {
    const response = await fetch(`${ROOT_URL}/api/tareas`, requestOptions)
    const data = await response.json()
    return data

  } catch(error){
    console.log(error)
  }
}


export async function getSubTasks(idTask, dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/subtareas/${idTask}`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_SUBTAREAS", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "LOGIN_ERROR", error: error });
    console.log(error + "try catch actions getTasks");
  }
}


export const setSubTask = async (idTask, payload) => {
  const requestOptions = {
    method : "POST",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
    body: JSON.stringify(payload)
  }
  try {
    const response = await fetch(`${ROOT_URL}/api/subtareas/${idTask}`, requestOptions);
    const data = await response.json()
    return data

  } catch(error){
    console.log(error)
  }
}


export const setClient = async (payload) => {
  const requestOptions = {
    method : "POST",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
    body: JSON.stringify(payload)
  }
  try {
    const response = await fetch(`${ROOT_URL}/api/clientes`, requestOptions);
    const data = await response.json()
    return data

  } catch(error){
    console.log(error)
  }
}

export const setIndustry = async (payload) => {
  const requestOptions = {
    method : "POST",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
    body: JSON.stringify(payload)
  }
  try {
    const response = await fetch(`${ROOT_URL}/api/industrias`, requestOptions);
    const data = await response.json()
    return data

  } catch(error){
    console.log(error)
  }
}

export async function updateSubTask(id, payload) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
    body: JSON.stringify(payload)
  };
  //console.log(requestOptions.body)
  try {
    const response = await fetch(`${ROOT_URL}/api/subtareas/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error)
  }
}

export async function updateUser(id, payload) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
    body: JSON.stringify(payload)
  };
  //console.log(requestOptions.body)
  try {
    const response = await fetch(`${ROOT_URL}/api/users/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error)
  }
}

export async function updateClient(id, payload) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
    body: JSON.stringify(payload)
  };
  //console.log(requestOptions.body)
  try {
    const response = await fetch(`${ROOT_URL}/api/clientes/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error)
  }
}

export async function updateIndustry(id, payload) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
    body: JSON.stringify(payload)
  };
  //console.log(requestOptions.body)
  try {
    const response = await fetch(`${ROOT_URL}/api/industrias/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error)
  }
}

export async function updateTask(id, payload) {
  const requestOptions = {
    method: "PUT",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token},
    body: JSON.stringify(payload)
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/tareas/${id}`, requestOptions);
    const data = await response.json();
    return data;
  } catch (error) {
    console.log(error)
  }
}

///GET TASK BY ID
export async function getOneTask(idTask, dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/tareas/${idTask}`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_TAREA", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "GET_TAREA_ERROR", error: error });
    //console.log(error + "try catch actions getTasks");
  }
}

///GET USER BY ID
export async function getUserById(id, dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/users/${id}`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_USER_BY_ID", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "GET_USER_BY_ID_ERROR", error: error });
    console.log(error + "try catch actions getUserById");
  }
}

///GET CLIENT BY ID

export async function getClientById(id, dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/clientes/${id}`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_CLIENT_BY_ID", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "GET_CLIENT_BY_ID_ERROR", error: error });
    console.log(error + "try catch actions getClientById");
  }
}


///GET INDUSTRY BY ID

export async function getIndustryById(id, dispatch) {
  const requestOptions = {
    method: "GET",
    headers: { "Content-Type": "application/json", "token": JSON.parse(localStorage.getItem("currentUser")).token },
  };
  try {
    const response = await fetch(`${ROOT_URL}/api/industrias/${id}`, requestOptions);
    const data = await response.json();
    dispatch({ type: "GET_INDUSTRY_BY_ID", payload: data });
    return data;
  } catch (error) {
    dispatch({ type: "GET_INDUSTRY_BY_ID_ERROR", error: error });
    console.log(error + "try catch actions getIndustryById");
  }
}