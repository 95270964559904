import React from "react";
import { Box } from "@mui/system";
import { Typography, IconButton , Grid} from "@mui/material";
import ReusableTable from "../utils/ReusableTable";
import { useAuthDispatch, getIndustries, setIndustry, deleteIndustry, updateIndustry, getIndustryById } from "../../context";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularIndeterminate from "../utils/CircularIndeterminate"
import EmptyPopUp from "../utils/EmptyPopup"
import ReusableButton from "../utils/ReusableButton";
import PopUp from "../utils/Popup"
import IndustryForm from "./IndustriasForm";
import swal from "sweetalert";


function Industrias() {
  const dispatch = useAuthDispatch();
  const [industries, setIndustries] = React.useState([]);
  const [error, setError] = React.useState();
  const [openIndustriasPopup, setOpenIndustriasPopup] = React.useState(false);
  const [openFormPopUp, setOpenFormPopUp] = React.useState(false);
  const [openFormEdit, setOpenFormEdit] = React.useState(false);
  const [selectedIndustry, setSelectedIndustry] = React.useState(null);
  const [idIndustry, setIdIndustry] = React.useState(null)



  const handleEdit = (idIndustry, industryData) => {
    setIdIndustry(idIndustry);
    setSelectedIndustry(industryData)
    setOpenFormEdit(true);
  };


  const handleDelete = async (id) => {
    try {
      swal({
        title: "Esta seguro que quiere eliminar esta industria?",
        text: "Una vez eliminada, no será posible recuperarlo!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          await deleteIndustry(dispatch, id);
          fetchIndustrias();
          swal("Industria eliminado satisfactoriamente!", {
            icon: "success",
          });
        } else {
          swal("Eliminación cancelada!");
        }
      });
    } catch (error) {
      // Handle the error and show an error notification if needed
    }
  };

  const fetchIndustrias = async () => {
    try {
      setOpenIndustriasPopup(true);
       let response = await getIndustries(dispatch);
        setError(null);
        setIndustries(response.info);
        setOpenIndustriasPopup(false);
      }
    catch (error) {
      setError("Error al cargar los datos");
      setIndustries([]);
    }
  };
  

  React.useEffect(() => {

    fetchIndustrias();

    return () => {
      setIndustries([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);  // <<REEMPLAZAR POR ESTO SI SE QUIERE RENDERIZAR CADA SEG  }, [dispatch, state.clients, refreshCount]);


  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Cuit-Cuil",
        accessor: "cuit_cuil",
        canSort: true,
        type: "String"
      },
      {
        Header: "Potencial",
        accessor: "potencial",
        canSort: true,
        type: "String",
      },
      {
        Header: "Estado",
        accessor: "estado",
        canSort: true,
        type: "String",
      },
      {
        Header: "Domicilio",
        accessor: "domicilio",
        canSort: true,
        type: "String",
      },
      {
        Header: "Ciudad",
        accessor: "ciudad",
        canSort: true,
        type: "String",
      },
      {
        Header: "Nombre Comercial",
        accessor: "nombre_comercial",
        type: "Number",
      },
      {
        Header: "Codigo de transporte",
        accessor: "codigo_transporte",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Codigo Zona",
        accessor: "codigo_zona",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Codigo Postal",
        accessor: "codigo_postal",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Telefono",
        accessor: "telefono",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Email",
        accessor: "email",
        canSort: true,
        type: "String",
      },
      {
        Header: "Contacto",
        accessor: "contacto",
        canSort: true,
        type: "String",
      },
      {
        Header: "Razon Social",
        accessor: "razon_social",
        canSort: true,
        type: "String",
      },
      {
        Header: "Notas",
        accessor: "notas",
        canSort: true,
        type: "String",
      },
      {
        Header: "Aniversario",
        accessor: "fecha_inicio",
        canSort: true,
        type: "Date",
      },
      {
        Header: "Sitio Web",
        accessor: "sitio_web",
        canSort: true,
        type: "String",
      },
      {
        Header: "Rubro",
        accessor: "rubro",
        canSort: true,
        type: "String",
      },
      {
        Header: "Direccion Fiscal",
        accessor: "direccion_fiscal",
        canSort: true,
        type: "String",
      },
      {
        Header: "Editar",
        accessor: "editar",
        canSort: false,
        type: "EditButton",
      },
      {
        Header: "Eliminar",
        accessor: "eliminar",
        canSort: false,
        type: "DeleteButton",
      },
    ],
    []
  );

  
  const columnsToShowInitially = ['ID','Potencial', 'Estado', 'Nombre Comercial','Contacto','Telefono', 'Email']

  const handleRefresh = async (event) => {

    setOpenIndustriasPopup(true)

      fetchIndustrias();
  }

  const editIndustry = async (idIndustry, values) => {
    await updateIndustry(idIndustry, values)
    setOpenFormEdit(false)
    fetchIndustrias();
  };


  const handleSubmit = async (values) => {
    await setIndustry(values)
    setOpenFormPopUp(false)
    fetchIndustrias();
  };


  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      {(error !== "") ? ( <div className = "error">{error}</div>) : ""}
      <Typography variant="h4" sx={{ textAlign:"center"}}>
        Industrias
      </Typography>
      <Grid container alignItems="center" >
      <IconButton sx={{ marginLeft:"1%"}} 
          aria-label="refresh" 
          onClick={() => {
            handleRefresh();
          }}>
                  <RefreshIcon/>
          </IconButton>
          <ReusableButton 
          text="nueva industria" 
          variant="outlined" 
          color="primary" 
          onClick={()=> setOpenFormPopUp(true) } type="button" 
          />
        </Grid>
          <PopUp
            title="Ingresar Industria"
            openPopup={openFormPopUp}
            setOpenPopup={setOpenFormPopUp}>
            <IndustryForm addIndustry={handleSubmit} />
          </PopUp>
          <PopUp
            title="Editar Industria"
            openPopup={openFormEdit}
            setOpenPopup={setOpenFormEdit}>
            <IndustryForm 
            editMode={editIndustry}
            idIndustry={idIndustry}
            initialValues={selectedIndustry} // Pass the initial values here
             />
          </PopUp>
          <EmptyPopUp
          title="Cargando..."
          openPopup={openIndustriasPopup}
          setOpenPopup={setOpenIndustriasPopup}>
         <CircularIndeterminate />
        </EmptyPopUp>
        <ReusableTable
            columns={columns}
            data={industries || []}
            columnsToShowInitially={columnsToShowInitially}
            onEditButtonClick={handleEdit}
            onDeleteButtonClick={handleDelete}
          />        
    </Box>
  );
}

export default Industrias;