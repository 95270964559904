import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  TableContainer,
} from '@mui/material';
import Notification from './Notification';
import { usePagination, useTable, useSortBy } from 'react-table';
import { TableDataRow } from './TableDataRow';

const ReusableTable = ({
  columns,
  data,
  columnsToShowInitially,
  onEditButtonClick,
  onDeleteButtonClick,
  showDownloadButton, // New prop to determine whether to show the Download button
  onDownloadFileClick, 
}) => {
  const [pagenbr, setPagenbr] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [notify, setNotify] = React.useState({ isOpen: false, message: '', type: 'error' });
  const userId =  JSON.parse(localStorage.getItem("currentUser")).user.id
  const role =  JSON.parse(localStorage.getItem("currentUser")).user.role

  const {
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0 },
    },
    useSortBy, // add pagination functionality
    usePagination // add sorting functionality
  );

  const handleChangePage = (event, newPage) => {
    setPagenbr(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPagenbr(0);
  };
const even = (element) => element?.titulo?.length > 0;

if(data?.some(even)){
  //filtrado de las tareas solamente para la ReusableTable
  if( role !== 'admin') data = data?.filter(data=>data.usuarioId === userId)
}
  return (
    <Paper sx={{ backgroundColor: '#F3F3F3', width: '100%', margin: '1%', display: 'inline-block', width: 'fit-content' }}>
      <Box sx={{ overflowX: 'auto', backgroundColor: '#F3F3F3' }}>
        <Notification notify={notify} setNotify={setNotify} />
        <TableContainer sx={{ backgroundColor: '#ffffff', overflowX: 'auto'}}>

          <Table
            sx={{ minWidth: 650, maxWidth: 1200, backgroundColor: '#F3F3F3', width: 'max-content' }}
            aria-labelledby="simple table"
            size={'small'}
          >
            <TableHead style={{ backgroundColor: '#ffffff', height: 50, whiteSpace: 'nowrap' }}>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers
                    .filter((column) => columnsToShowInitially.includes(column.Header))
                    .map((column) => (
                      <TableCell
                        sx={{ fontWeight: 'bold' }}
                        {...column.getHeaderProps(column.getSortByToggleProps())}
                      >
                        {column.render('Header')}
                        <span>
                          {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                        </span>
                      </TableCell>
                    ))}
                </TableRow>
              ))}
            </TableHead>
            {data.length > 0 && (
              <TableBody {...getTableBodyProps()}>
                {data.slice(pagenbr * rowsPerPage, pagenbr * rowsPerPage + rowsPerPage).map((row, key) => (
                  <TableDataRow
                  sx={{ backgroundColor: '#F3F3F3' }}
                  key={key}
                  row={row}
                  columns={columns}
                  columnsToShow={columnsToShowInitially}
                  onEditButtonClick={onEditButtonClick}
                  onDeleteButtonClick={onDeleteButtonClick}
                  onDownloadFileClick={onDownloadFileClick} // Pass the prop here
                />
                ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <TablePagination
          sx={{ color: '#ffffff', backgroundColor: '#2C2C2C' }}
          rowsPerPageOptions={[50, 75, 100, { label: 'All', value: -1 }]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={pagenbr}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Box>
    </Paper>
  );
};

export default ReusableTable;