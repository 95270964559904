import React from "react";
import { Box } from "@mui/system";
import { Typography, IconButton } from "@mui/material";
import ReusableTable from "../utils/ReusableTable";
import { useAuthDispatch, getClients, setClient, deleteClient, updateClient } from "../../context";
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularIndeterminate from "../utils/CircularIndeterminate"
import EmptyPopUp from "../utils/EmptyPopup"
import ReusableButton from "../utils/ReusableButton";
import PopUp from "../utils/Popup"
import ClientForm from "./ClientesForm";
import swal from "sweetalert";


function Clientes() {
  const dispatch = useAuthDispatch();
  const [clients, setClients] = React.useState([]);
  const [error, setError] = React.useState();
  const [openClientesPopup, setOpenClientesPopup] = React.useState(false);
  const [openFormPopUp, setOpenFormPopUp] = React.useState(false);
  const [openFormEdit, setOpenFormEdit] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [idClient, setIdClient] = React.useState(null)


  const handleEdit = (idClient, clientData) => {
    setIdClient(idClient);
    setSelectedClient(clientData)
    setOpenFormEdit(true);
  };

  const handleDelete = async (id) => {
    try {
      swal({
        title: "Esta seguro que quiere eliminar este cliente?",
        text: "Una vez eliminado, no será posible recuperarlo!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          await deleteClient(dispatch, id);
          fetchClients();
          swal("Cliente eliminado satisfactoriamente!", {
            icon: "success",
          });
        } else {
          swal("Eliminación cancelada!");
        }
      });
    } catch (error) {
      // Handle the error and show an error notification if needed
    }
  };
  

  const fetchClients = async () => {
    try {
        setOpenClientesPopup(true)
        let response = await getClients(dispatch);
        setError(null);
        setClients(response.info);
        setOpenClientesPopup(false)
    } catch (error) {
      setError("Error al cargar los datos");
      setClients([]);
    }
  };


  React.useEffect(() => {

    
    fetchClients();

    return () => {
      setClients([]);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);  // <<REEMPLAZAR POR ESTO SI SE QUIERE RENDERIZAR CADA SEG  }, [dispatch, state.clients, refreshCount]);


  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Potencial",
        accessor: "potencial",
        canSort: true,
        type: "String",
      },
      {
        Header: "Estado",
        accessor: "estado",
        canSort: true,
        type: "String",
      },
      {
        Header: "Domicilio",
        accessor: "domicilio",
        canSort: true,
        type: "String",
      },
      {
        Header: "Ciudad",
        accessor: "ciudad",
        canSort: true,
        type: "String",
      },
      {
        Header: "Nombre Comercial",
        accessor: "nombre_comercial",
        canSort: true,
        type: "String",
      },
      {
        Header: "Contacto",
        accessor: "contacto",
        canSort: true,
        type: "String",
      },
      {
        Header: "Codigo de transporte",
        accessor: "codigo_transporte",
        type: "Number",
      },
      {
        Header: "Codigo de transporte",
        accessor: "codigo_zona",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Codigo Postal",
        accessor: "codigo_postal",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Telefono",
        accessor: "telefono",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Email",
        accessor: "email",
        canSort: true,
        type: "String",
      },
      
      {
        Header: "Razon Social",
        accessor: "razon_social",
        canSort: true,
        type: "String",
      },
      {
        Header: "Notas",
        accessor: "notas",
        canSort: true,
        type: "String",
      },
      {
        Header: "Aniversario",
        accessor: "fecha_inicio",
        canSort: true,
        type: "Date",
      },
      {
        Header: "Sitio Web",
        accessor: "sitio_web",
        canSort: true,
        type: "String",
      },
      {
        Header: "Rubro",
        accessor: "rubro",
        canSort: true,
        type: "String",
      },
      {
        Header: "Direccion Fiscal",
        accessor: "direccion_fiscal",
        canSort: true,
        type: "String",
      },
      {
        Header: "CuitCuil",
        accessor: "cuit_cuil",
        canSort: true,
        type: "String"
      },
      {
        Header: "Editar",
        accessor: "editar",
        canSort: false,
        type: "EditButton",
      },
      {
        Header: "Eliminar",
        accessor: "eliminar",
        canSort: false,
        type: "DeleteButton",
      },
    ],
    []
  );


  const columnsToShowInitially = ['ID','Potencial', 'Estado', 'Nombre Comercial','Contacto','Telefono', 'Email']


  const handleRefresh = async (event) => {

    setOpenClientesPopup(true)

      fetchClients();
  }

  const editClient = async (idClient, values) => {
    await updateClient(idClient, values)
    setOpenFormEdit(false)
    fetchClients();
  };

  

  const handleSubmit = async (values) => {
    await setClient(values)
    setOpenFormPopUp(false)
    fetchClients();
  };


  return (
    <Box xs={{ width: '100%',  }}>
      {(error !== "") ? ( <div className = "error">{error}</div>) : ""}
      <Typography variant="h4" sx={{ textAlign:"center"}}>
        Clientes
      </Typography>
      <IconButton sx={{ m:"2%"}} 
          aria-label="refresh" 
          onClick={() => {
            handleRefresh();
          }}>
              <RefreshIcon/>
          </IconButton>
          <ReusableButton 
          text="nuevo cliente" 
          variant="outlined" 
          color="primary" 
          onClick={()=> setOpenFormPopUp(true) } type="button" 
          />
          <PopUp
            title="Ingresar Cliente"
            openPopup={openFormPopUp}
            setOpenPopup={setOpenFormPopUp}>
            <ClientForm addClient={handleSubmit} />
          </PopUp>
          <PopUp
            title="Editar Cliente"
            openPopup={openFormEdit}
            setOpenPopup={setOpenFormEdit}>
            <ClientForm 
             editMode={editClient}
             idClient={idClient}
             initialValues={selectedClient}
              />
          </PopUp>
          <EmptyPopUp
          title="Cargando..."
          openPopup={openClientesPopup}
          setOpenPopup={setOpenClientesPopup}>
         <CircularIndeterminate />
        </EmptyPopUp>
        <ReusableTable
            columns={columns}
            data={clients || []}
            columnsToShowInitially={columnsToShowInitially}
            onEditButtonClick={handleEdit}
            onDeleteButtonClick={handleDelete}
          />
    </Box>
  );
}

export default Clientes;