import React from 'react';
import { FormControl, Grid, TextField, Paper, Button, Select, MenuItem, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import { UseForm, Form } from '../utils/UseForm';
import { useAuthDispatch, useAuthState } from "../../context";
import { FormHelperText } from '@material-ui/core';
import swal from "sweetalert";
import { roleOptions } from './utils';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';

const initialFieldValues = {
  firstName: "",
  lastName: "",
  username: "",
  password:"",
  role: "",
}

export default function UserForm(props) {

  const { initialValues, addUser, editMode, idUser } = props;
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);

  UseForm();

  const validate = (fieldValues = values) => {
    let temp = { ...errors } 
    if ('firstName' in fieldValues)
      temp.firstName = fieldValues.firstName ? "" : "Debe proporcionar este campo."
    if ('lastName' in fieldValues)
      temp.lastName = fieldValues.lastName ? "" : "Debe proporcionar este campo."
    if ('username' in fieldValues)
      temp.username = fieldValues.username ? "" : "Debe proporcionar este campo."
    if ('password' in fieldValues)
      temp.password = fieldValues.password ? "" : "Debe proporcionar este campo."
    if ('role' in fieldValues)
      temp.role = fieldValues.role ? "" : "Debe proporcionar este campo."
    
      temp.confirmPassword =
      fieldValues.confirmPassword === fieldValues.password
        ? ''
        : 'Las contraseñas no coinciden';
  
    setErrors({
      ...temp
    });
  
    // Explicitly set confirmPassword error to empty string when passwords match
    if (fieldValues.confirmPassword === fieldValues.password && errors.confirmPassword !== '') {
      setErrors({
        ...temp,
        confirmPassword: ''
      });
    }
  
    if (fieldValues === values) {
      return Object.values(temp).every(x => x === '');
    }
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = UseForm(initialFieldValues, true, validate, initialValues);


  const handleSubmit = async e => {
    e.preventDefault();

    const actionText = editMode ? "editado" : "creado";
    
    swal({
      title: "Confirmar envío",
      text: "¿Está seguro de que desea enviar el formulario?",
      icon: "info",
      buttons: ["Cancelar", "Confirmar"],
      dangerMode: false,
    }).then(async (confirm) => {
      if (confirm) {
        if (validate()) {
          if (editMode) {
            editMode(idUser, values)
          } else {
            await addUser(values);
          }
          
          swal("OK", `El Usuario fue ${actionText} correctamente!`, "success");
        } else {
          swal("Error", "Favor de cargar todos los campos obligatorios.", "error");
        }
      }
    });
  };

  // const areFieldsCompleted = () => {
  //   return Object.values(values).every((value) => value.trim() !== "");
  // };

  return (
    <Paper sx={{ backgroundColor:"#FFFFFF", borderRadius: 1,boxShadow:0, margin: '5px 5px', maxWidth: '100%'}}>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <TextField
            autoComplete="off"
            size="small"
            type="text" 
            variant="outlined"
            label="Nombre"
            name="firstName"
            sx={{background:"#ffffff", borderRadius: 1}}
            value={values.firstName ? values.firstName : ''}
            onChange={e => handleInputChange(e,"firstName", null, false)} 
            error={Boolean(errors.firstName)}
            helperText={errors.firstName}
            FormHelperTextProps={{ sx: { color: 'red' } }}
          />
          <TextField
            autoComplete="off"
            size="small"
            type="text" 
            variant="outlined"
            label="Apellido"
            name="lastName"
            sx={{background:"#ffffff", borderRadius: 1}}
            value={values.lastName ? values.lastName : ''}
            onChange={e => handleInputChange(e,"lastName", null, false)} 
            error={Boolean(errors.lastName)}
            helperText={errors.lastName}
            FormHelperTextProps={{ sx: { color: 'red' } }}
          />
          <TextField
            autoComplete="off"
            size="small"
            type="text" 
            variant="outlined"
            label="Usuario"
            name="username"
            sx={{background:"#ffffff", borderRadius: 1}}
            value={values.username ? values.username : ''}
            onChange={e => handleInputChange(e,"username", null, false)} 
            error={Boolean(errors.username)}
            helperText={errors.username}
            FormHelperTextProps={{ sx: { color: 'red' } }}
          />
          <Grid item xs={6}>
            <Box sx={{ width: 240 }}>
              <FormControl fullWidth error={Boolean(errors.role)}>
                <InputLabel  id="demo-simple-select-label">Rol</InputLabel>
                <Select value={values.role ? values.role : ''} 
                        onChange={e => handleInputChange(e,"role", null, false)} 
                        name="role"
                        size="small"
                        sx={{background:"#ffffff", borderRadius: 1}}>
                  {roleOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error color="error">{errors.role}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        <TextField
            autoComplete="off"
            size="small"
            type={showPassword ? 'text' : 'password'}
            variant="outlined"
            label="Contraseña"
            name="password"
            sx={{ background: "#ffffff", borderRadius: 1 }}
            value={values.password ? values.password : ''}
            onChange={e => handleInputChange(e, "password", null, false)}
            error={Boolean(errors.password)}
            helperText={errors.password}
            FormHelperTextProps={{ sx: { color: 'red' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            autoComplete="off"
            size="small"
            type={showConfirmPassword ? 'text' : 'password'}
            variant="outlined"
            label="Confirmar Contraseña"
            name="confirmPassword"
            sx={{ background: "#ffffff", borderRadius: 1 }}
            value={values.confirmPassword ? values.confirmPassword : ''}
            onChange={e => handleInputChange(e, "confirmPassword", null, false)}
            error={Boolean(errors.confirmPassword) && values.confirmPassword !== values.password}
            helperText={values.confirmPassword === values.password ? '' : errors.confirmPassword}
            FormHelperTextProps={{ sx: { color: 'red' } }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    {showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        <Button
          type="submit"
          className="add-task"
          variant="contained"
        //  disabled={!areFieldsCompleted()}
          sx={{
            fontWeight: 'bold',
            border: 1,
            borderColor: "black",
            margin: "3%",
            backgroundColor: '#ffffff',
            color: '#111315',
            "&:hover": { borderColor: "#ffffff", color: '#ffffff', backgroundColor: "#111315" }
          }}
        >
          {editMode ? 'EDITAR' : 'CREAR'}
        </Button>
      </Form>
    </Paper>
  );
}
