import React from "react";
import { Grid, Button, InputAdornment, IconButton,Box,TextField } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { loginUser, useAuthDispatch } from "../../context";
import { useHistory } from "react-router-dom";
//import logo from "../../assets/Asset29.png";
import fondobg from '../../assets/bg.jpeg';
import "./LoginForm.css";

const LoginForm = () => {
  const [user, setUser] = React.useState("");
  const [error, setError] = React.useState("");
  const [showPassword, setShow] = React.useState(false);
  const [password, setPassword] = React.useState("");
  const dispatch = useAuthDispatch();
  let history = useHistory();

  
  const handleInputChange = (e) => {
   // e.preventDefault();
    setUser(e.target.value)
  };
  const handlePassChange = (p) => {
    //p.preventDefault();
    setPassword(p.target.value)
  };

  const handleClickShowPassword = () => {
    setShow(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const submitCredentials = async (e) => {
    e.preventDefault();
    //  console.log({ user, password });
    const credentials = {
      
      username: user.toLowerCase().trim(),
      // password: password.toLowerCase().trim(),
      password: password.trim(),
    };
    if (!user || !password) {
      setError("Porfavor introducir un usuario y/o contraseña");
    } else {
      try {
        setError("");
      //  console.log("credentials: ", credentials);
        let response = await loginUser(dispatch, credentials);
         //console.log("response: ", response.ok);
        // console.log(credentials);
        if (!response.ok) {
          setError("Usuario o contraseña incorrectos");
          return;
        }
        history.push("/tareas");
      } catch (error) {
        setError("Usuario o contraseña incorrectos");
        //console.log(error);
      }
    }
  };

  const EndAdornment = () => {
    return (
      <InputAdornment position="end">
        <IconButton
          aria-label="toggle password visibility"
          onClick={handleClickShowPassword}
          onMouseDown={handleMouseDownPassword}
          edge="end"
        >
          {showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    );
  };

  return (
    
    <Grid container height='100vh' width='100%'>
      <Grid item xs={12} md={6}  height='100vh'sx={{ height: '100vh', width: '100%' }}>
      <img
        src={fondobg}
        height= '100%'
        width= '100%'
        sx={{
          objectFit: 'cover',
          position: 'absolute',
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          height: '100%',
          width: '100%',
          transform: 'scale(1.99)'
        }}
      />
      </Grid>
      <Grid item xs={12} md={6} height={98}  paddingLeft={23} paddingRight={23} sx={{ height:'100vh',display: "flex", alignItems: "center" ,color:'#FFFFFF',backgroundColor:'#FFFFFF',}}>
        <Box sx={{ width: "100%", height:'100vh',}}>
          <Box mb={30}></Box>
          <h1 style={{ fontFamily: 'Poppins', color:'#000000',fontWeight:'bold' }}> Bienvenido </h1>
          <h2 style={{ fontFamily: 'Poppins', color:'#000000',opacity:0.7,fontSize:16,fontWeight:'normal' }}>Ingrese su usuario para continuar</h2>
          <form className="form" sx={{height:'100%'}} >
            <Box mb={3}>
            <TextField
                label="Usuario"
                variant="outlined"
                fullWidth
                value={user}
                sx={{
                  input: {
                    color: '#000000',
                  },
                  }}
                onChange={handleInputChange}
              />
            </Box>
            <Box mb={3}>
            <TextField
                label="Contraseña"
                type="password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={handlePassChange}
                sx={{color:'#FFFFFF', }}
              />
            </Box>
            <p className="error-text" >{error}</p>
            <Button  
              onClick={submitCredentials}
              className="login-btn" 
              variant="contained" 
              type="submit" 
              sx={{ 
               width: "100%",
                color:'#FFFFFF',
                backgroundColor:'#323338', 
             }}
            >
              Iniciar Sesión
            </Button>
          
          </form>
        </Box>
      </Grid>
    </Grid>
  
  );
};

export default LoginForm;
