let user = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).user
  : "";
let token = localStorage.getItem("currentUser")
  ? JSON.parse(localStorage.getItem("currentUser")).token
  : false;

export const initialState = {
  user: "" || user,
  token: "" || token,
  loading: false,
  errorMessage: null,
  users: [],
  usersList: [],
  oneUser: [],
  userId : [],
  tasks : [],
  clients : [],
  industries: [],
  subtasks: [],
  oneTask: [],
  oneClient:[],
  oneIndsutry:[],
};

export const AuthReducer = (initialState, action) => {
  switch (action.type) {
    case "REQUEST_LOGIN":
      return {
        ...initialState,
        loading: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...initialState,
        user: action.payload.user,
        token: action.payload.token,
        loading: false,
        role: action.payload.user.role,
      };
    case "LOGOUT":
      return {
        ...initialState,
        user: "",
        token: "",
        role: "",
      };

    case "LOGIN_ERROR":
      return {
        ...initialState,
        loading: false,
        errorMessage: action.error,
      };
    
    case "GET_USERS":
        return {
          ...initialState,
          users: action.payload.info
        }
    case "GET_ID_USER":
      return {
        ...initialState,
        userId: action.payload
      };
    case "GET_TAREAS":
      return {
        ...initialState,
        tasks: action.payload.info
      };
      case "GET_TAREA":
      return {
        ...initialState,
        oneTask: action.payload.info
      };
      case "GET_SUBTAREAS":
      return {
        ...initialState,
        subtasks: action.payload.info
      };
      case "GET_CLIENTES":
      return {
        ...initialState,
        clients: action.payload.info
      };
      case "GET_INDUSTRIAS":
      return {
        ...initialState,
        industries: action.payload.info
      };
      case "GET_USER":
      return {
        ...initialState,
        oneUser: action.payload.info
      };
      case "GET_INDUSTRIA":
      return {
        ...initialState,
        oneIndustry: action.payload.info
      };
      case "GET_CLIENTE":
      return {
        ...initialState,
        oneClient: action.payload.info
      };
    default:
      try {
        return initialState
      } catch (error) {
        throw new Error(`Unhandled action type: ${action.type}, ${error}`);
      }
      
  }
};