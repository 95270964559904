import { Dialog, DialogContent, DialogTitle, Typography } from '@mui/material';
import React from 'react';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    dialogWrapper: {
        padding : theme.spacing(2),
        position: "absolute",
        top: theme.spacing(5)
    }
}))

export default function EmptyPopUp(props) {

  const classes = useStyles;
  const { title, children, openPopup } = props;
  return (
    <Dialog open={openPopup} maxWidth="md" classes={{paper: classes.dialogWrapper}}>
        <DialogTitle
        sx={{fontWeight:'bold', background:"#F1F1F2"}}>
            <div style={{display:'flex'}}>
            <Typography variant="h6" component="div" style={{flexGrow:1}}>
                {title}

            </Typography>
            </div>
        </DialogTitle>
        <DialogContent sx={{ background:"#F1F1F2"}}dividers>
            {children}
        </DialogContent>
    </Dialog>
  )
}
