import React from "react";
import { Box } from "@mui/system";
import { Typography, IconButton } from "@mui/material";
import ReusableTable from "../utils/ReusableTable";
import { useAuthDispatch, getSubTasks, useAuthState, setSubTask, deleteSubtask, updateSubTask, downloadFile } from "../../context";
import { useParams } from 'react-router-dom';
import RefreshIcon from '@mui/icons-material/Refresh';
import CircularIndeterminate from "../utils/CircularIndeterminate"
import EmptyPopUp from "../utils/EmptyPopup"
import PopUp from "../utils/Popup"
import ReusableButton from "../utils/ReusableButton";
import SubtasksForm from "./SubtareasForm";
import WestIcon from '@mui/icons-material/West';
import swal from "sweetalert";

function Subtareas() {
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const [subtasks, setSubTasks] = React.useState([]);
  //const [newTasks, setnewTasks] = React.useState([]);
  const [error, setError] = React.useState();
  const [openEmptyPopup, setOpenEmptyPopup] = React.useState(false);
  const [openFormPopUp, setOpenFormPopUp] = React.useState(false);
  const [openFormEdit, setopenFormEdit] = React.useState(false);
  const [selectedSubtask, setSelectedSubtask] = React.useState(null);
  const [idSubTask, setIdSubTask] = React.useState(null);


  const { id } = useParams();


  const handleEdit = (idSubtask, subtaskData) => {
    setIdSubTask(idSubtask);
    setSelectedSubtask(subtaskData)
    setopenFormEdit(true);
  };


  const handleDelete = async (id) => {
    try {
      swal({
        title: "Esta seguro que quiere eliminar esta subtarea?",
        text: "Una vez eliminada, no será posible recuperarlo!",
        icon: "warning",
        buttons: ["Cancel", "Delete"],
        dangerMode: true,
      }).then(async (confirm) => {
        if (confirm) {
          await deleteSubtask(dispatch, id);
          fetchSubTasks();
          swal("Subtarea eliminado satisfactoriamente!", {
            icon: "success",
          });
        } else {
          swal("Eliminación cancelada!");
        }
      });
    } catch (error) {
      // Handle the error and show an error notification if needed
    }
  };

  const fetchSubTasks = async () => {
    try {
      setOpenEmptyPopup(true)
      let response = await getSubTasks(id, dispatch);
      console.log("Subtasks Response:", response.info);
      setSubTasks(response.info)
      setOpenEmptyPopup(false)
    } catch {
      setError("Error al cargar los datos");
      setSubTasks([])
    }
  }
  

  React.useEffect(() => {
    fetchSubTasks();

    return () => {
      setSubTasks([])
    }
  },[])

  

  

  const columns = React.useMemo(
    () => [
      {
        Header: "ID",
        accessor: "id",
        canSort: true,
        type: "Number",
      },
      {
        Header: "Titulo",
        accessor: "titulo",
        canSort: true,
        type: "String",
      },
      {
        Header: "Adjuntos",
        accessor: "idArchivo",
        canSort: true,
        type: "String",
      },
      {
        Header: "Llamada",
        accessor: "llamada",
        canSort: true,
        type: "Boolean",
      },
      {
        Header: "Estado",
        accessor: "estado",
        canSort: true,
        type: "String",
      },
      {
        Header: "Visita Presencial",
        accessor: "visita_presencial",
        canSort: true,
        type: "Boolean",
      },
      {
        Header: "Fecha Actividad",
        accessor: "updatedAt",
        type: "DateTime",
      },
      {
        Header: "Descripcion",
        accessor: "descripcion",
        canSort: true,
        type: "String",
      },
      {
        Header: "Email",
        accessor: "email",
        canSort: true,
        type: "Boolean"
      },
      {
        Header: "Finalizado",
        accessor: "finalizado",
        canSort: true,
        type: "Boolean"
      },
      {
        Header: "Editar",
        accessor: "editar",
        canSort: false,
        type: "EditButton",
      },
      {
        Header: "Eliminar",
        accessor: "eliminar",
        canSort: false,
        type: "DeleteButton",
      },
      {
        Header: "Descargar",
        accessor: "descargar",
        canSort: false,
        type: "DownloadButton", // Define a custom type for the download button
      },
      {
        Header: "nombreArchivo",
        accessor: "nombreArchivo",
        canSort: false,
        type: "String", // Define a custom type for the download button
      },
    ],
    []
  );

  const handleDownloadFile = (row) => {
    downloadFile(row.idArchivo, row.nombreArchivo);
  };
  
  const columnsToShowInitially = ['Titulo','Estado', 'Adjuntos', 'Email','Visita Presencial','Llamada', 'Fecha Actividad', 'Descripcion', 'Finalizado']


  const handleRefresh = async () => {

    setOpenEmptyPopup(true)

    const getData = async () => {
        try {
          await getSubTasks(id, dispatch);
            setError(null);
            var dataTareas = state?.subtasks;
            setSubTasks(dataTareas);
            setOpenEmptyPopup(false)
        } catch (error) {
          setError("Error al cargar los datos");
          setSubTasks([]);
        }
      };
      getData();
   
  }

  const handleGoBack = async () => {
    window.history.back();
  }



  const editSubtask = async (idSubtask, values) => {
    try {
      await updateSubTask(idSubtask, values);
      setopenFormEdit(false);
      fetchSubTasks(); // This will fetch all subtasks. You may want to optimize this to update only the edited subtask.
    } catch (error) {
      // Handle the error
    }
  };
  

  const handleSubmit = async (values) => {
    await setSubTask(id, values)
    setOpenFormPopUp(false)
    fetchSubTasks();
  };


  return (
    <Box sx={{ maxWidth: '100%', overflowX: 'auto' }}>
      {(error !== "") ? ( <div className = "error">{error}</div>) : ""}
      <Typography variant="h4" sx={{ textAlign:"center"}}>
        Subtareas
      </Typography>

      <IconButton sx={{ marginLeft:"1%"}} 
          aria-label="back" 
          onClick={() => {
            handleGoBack();
          }}>
                    <WestIcon/>
          </IconButton>
      
      <IconButton sx={{ marginLeft:"1%"}} 
          aria-label="refresh" 
          onClick={() => {
            handleRefresh();
          }}>
                    <RefreshIcon/>
          </IconButton>
          <ReusableButton 
          text="nueva subtarea" 
          variant="outlined" 
          color="primary" 
          onClick={()=> setOpenFormPopUp(true) } type="button" 
          />
          <PopUp
            title="Ingresar Subtarea"
            openPopup={openFormPopUp}
            setOpenPopup={setOpenFormPopUp}>
            <SubtasksForm addSubTask={handleSubmit} />
          </PopUp>
          <PopUp
            title="Editar Subtarea"
            openPopup={openFormEdit}
            setOpenPopup={setopenFormEdit}>
            <SubtasksForm 
             editMode={editSubtask}
             idSubtask={idSubTask}
             initialValues={selectedSubtask}  />

          </PopUp>
          <EmptyPopUp
          title="Cargando..."
          openPopup={openEmptyPopup}
          setOpenPopup={setOpenEmptyPopup}>
         <CircularIndeterminate />
        </EmptyPopUp>
        <ReusableTable
          columns={columns}
          data={subtasks || []}
          columnsToShowInitially={columnsToShowInitially}
          onDeleteButtonClick={handleDelete}
          onEditButtonClick={handleEdit}
          onDownloadFileClick={handleDownloadFile} // Pass the download callback function
        />
    </Box>
  );
}

export default Subtareas;