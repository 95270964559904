import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import routes from "./Config/routes";
import { AuthProvider } from "./context";
import AppRoute from "./components/AppRoutes";

function App() {

  

  React.useEffect(() => {
    function changebackground() {
      document.body.style.backgroundColor = "#F1F1F2";
      document.body.style.margin = 0;
    }
    changebackground();
  }, []);

 
  return (
    <AuthProvider>
      <Router>
        <Switch>
          {routes.map((route) => (
            <AppRoute 
              key={route.path}
              path={route.path}
              component={route.component}
              isPrivate={route.isPrivate}
            />
          ))}
        </Switch>
      </Router>
    </AuthProvider>
  );
}

export default App;
