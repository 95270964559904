import React, { useState} from 'react';
import dayjs from 'dayjs';
import { makeStyles } from '@material-ui/core';


export function UseForm(initialFieldValues, validateOnChange = false, validate, initialValues) {

    const [values, setValues] = useState(initialValues || initialFieldValues);
    const [errors, setErrors] = useState({})

    const handleInputChange = (e, name, dateValue, isdate) => {
      if (isdate) {
        const formattedDate = dayjs(dateValue).format() 
        setValues({
          ...values,
          [name]: formattedDate
        });
        if (validateOnChange) {
          validate({ [name]: formattedDate });    
        }
      }
      else
      {
        const { value, checked } = e.target;
        setValues({
          ...values,
          [name]: e.target.type === "checkbox" ? checked : value
        });
        if (validateOnChange) {
          validate({ [name]: value });
        }   
      }
    }
    

    
    return {
        values,
        setValues,
        handleInputChange,
        errors,
        setErrors
    }
}

const useStyles = makeStyles(theme => ({
    root: {
      '& .MuiFormControl-root': {
        width: '80%',
        margin: theme.spacing(0.5)
      }
    }
  }))


export function Form(props) {

    const classes = useStyles();
    const {children, ...other} = props;

    return (

    <form className={classes.root} autoComplete='off' { ...other }>
        {props.children}
    </form>
    )
}