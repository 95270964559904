import React, { useState } from "react";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem,
  IconButton,
  TablePagination,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";
import { updateTask } from "../../context";
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';

const TaskList = ({ tasks, users }) => {
  const [editingTasks, setEditingTasks] = useState({});
  const [tasksList, setTasksList] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editMode, setEditMode] = useState(false)

  // Actualiza tasksList cuando tasks o users cambian
  React.useEffect(() => {
    const updatedTasksList = tasks?.map((task) => {
      const userTask = users?.find((user) => user.id === task.usuarioId);
      return {
        id: task.id,
        taskName: task.titulo,
        usuarioId: task.usuarioId,
        estado: task.estado,
        cliente: task.clienteId,
        industria: task.industriaId,
        assignedUserName: `${userTask?.firstName} ${userTask?.lastName}`,
      };
    });
    setTasksList(updatedTasksList);
  }, [tasks, users]);

  const handleEdit = (taskId) => {
    setEditMode(true)
    setEditingTasks({ ...editingTasks, [taskId]: true });
  };

  const handleSave = async (taskId) => {
    setEditMode(false)
    setEditingTasks({ ...editingTasks, [taskId]: false });
    const taskToSave = tasksList?.find((task) => task.id === taskId);
    const modifiedTask = await updateTask(taskToSave.id, taskToSave)
    if(modifiedTask.success) window.location.reload()
  };

  const handleUserChange = (taskId, userId) => {
    const updatedTasksList = tasksList?.map((task) => {
      if (task.id === taskId) {
        return {
          ...task,
          usuarioId: userId,
        };
      }
      return task;
    });
    setTasksList(updatedTasksList);
  };

 

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ maxWidth: "100%", overflowX: "auto",  display: 'inline-block', width: 'fit-content' }}>
      <TableContainer component={Paper}>
      <Table
            sx={{ minWidth: 650, maxWidth: 1200, backgroundColor: '#F3F3F3', width: 'max-content' }}
            aria-labelledby="simple table"
            size={'small'}
          >
        <TableHead style={{ backgroundColor: '#ffffff', height: 50, whiteSpace: 'nowrap' }}>
            <TableRow sx={{ textAlign: "center" }}>
              <TableCell sx={{ fontWeight: 'bold' }}>Tarea</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Vendedor</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Estado</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Cliente</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Industria</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>
                {editMode ? 'Confirmar' : 'Reasignar'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(rowsPerPage > 0
              ? tasksList.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : tasksList
            ).map((task) => (
              <TableRow key={task.id} sx={{background:'#dedede'}}>
                <TableCell>{task.taskName}</TableCell>
                <TableCell>
                  {editingTasks[task.id] ? (
                    <Select
                      value={task.usuarioId}
                      onChange={(e) => handleUserChange(task.id, e.target.value)}
                    >
                      {users.map((user) => (
                        <MenuItem key={user?.id} value={user?.id}>
                          {`${user?.firstName} ${user?.lastName}`}
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    task.assignedUserName
                  )}
                </TableCell>
                <TableCell>{task.estado}</TableCell>
                <TableCell>{task.cliente}</TableCell>
                <TableCell>{task.industria}</TableCell>
                <TableCell>
                  {editingTasks[task.id] ? (
                    <IconButton onClick={() => handleSave(task.id)}><CheckIcon /></IconButton>
                  ) : (
                    <IconButton onClick={() => handleEdit(task.id)}><EditIcon /></IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          sx={{ color: '#ffffff', backgroundColor: '#2C2C2C' }}
          rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
          component="div"
          count={tasksList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

export default TaskList;
