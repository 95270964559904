import React from "react";
import { Grid } from "@material-ui/core";
import LoginForm from "../../components/Login/LoginForm";

function LoginPage() {
  return (
    <>
      <div >
      <LoginForm/>
      </div>
    </>
  );
}

export default LoginPage;
