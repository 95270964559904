import React from 'react';
import { FormControl, Grid, TextField, Paper, Button, Checkbox, FormControlLabel, Select, MenuItem, InputLabel } from '@mui/material';
import { Box } from '@mui/system';
import { UseForm, Form } from '../utils/UseForm';
import { useAuthDispatch, useAuthState, uploadFile } from "../../context";
import { FormHelperText } from '@material-ui/core';
import swal from "sweetalert";


const initialFieldValues = {
  titulo: "",
  adjuntos: "",
  llamada: true,
  estado: "",
  visita_presencial: false,
  descripcion: "",
  email: false,
  finalizado: false,
}

const estadoOptions = [
  { value: "seguimiento", label: "Seguimiento" },
  { value: "sin interés", label: "Sin interés" },
  { value: "diferido", label: "Diferido" },
  { value: "completa", label: "Completa" }
]

export default function SubtasksForm(props) {
  const { initialValues, addSubTask, editMode, idSubtask  } = props;
  const state = useAuthState();
  const dispatch = useAuthDispatch();
  const userId = JSON.parse(localStorage.getItem("currentUser")).user.id;
  initialFieldValues.userId = userId;
  const [selectedFile, setSelectedFile] = React.useState(null);

  UseForm();
  
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ('titulo' in fieldValues)
      temp.titulo = fieldValues.titulo ? "" : "Debe proporcionar este campo.";
    if ('estado' in fieldValues)
      temp.estado = fieldValues.estado ? "" : "Debe proporcionar este campo.";
    if ('descripcion' in fieldValues)
      temp.descripcion = fieldValues.descripcion ? "" : "Debe proporcionar este campo.";
    setErrors({
      ...temp
    });
    if (fieldValues === values)
      return Object.values(temp).every(x => x === "");
  }

  const {
    values,
    errors,
    setErrors,
    handleInputChange,
  } = UseForm(initialFieldValues, true, validate, initialValues);

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const actionText = editMode ? 'editada' : 'creada';
  
    swal({
      title: 'Confirmar envío',
      text: '¿Está seguro de que desea enviar el formulario?',
      icon: 'info',
      buttons: ['Cancelar', 'Confirmar'],
      dangerMode: false,
    }).then(async (confirm) => {
      if (confirm) {
        if (validate()) {
          try {
            let fileId = null;
            let fileName = null;
            if (selectedFile) {
              // Upload the file to Google Drive and get the file ID
              const response = await uploadFile(selectedFile);
              fileId = JSON.parse(response).id; // The response already contains the fileId
              fileName = JSON.parse(response).name; // The response already contains the fileId
            }
  
            // Whether or not a file is attached, create or edit the subtask
            if (!editMode) {
              // Append the fileId to the values object before adding the subtask
              const subtaskData = {
                titulo: values.titulo,
                idArchivo: fileId, // Save the fileId here
                llamada: values.llamada,
                estado: values.estado,
                visita_presencial: values.visita_presencial,
                descripcion: values.descripcion,
                email: values.email,
                finalizado: values.finalizado,
                userId: values.userId,
                nombreArchivo: fileName
              };
              await addSubTask(subtaskData);
            } else {
              // If not editing, directly edit the subtask
              editMode(idSubtask, values);
            }
  
            swal('OK', `La subtarea fue ${actionText} correctamente!`, 'success');
          } catch (error) {
            swal('Error', 'Ocurrió un error al enviar el formulario.', 'error');
          }
        } else {
          swal('Error', 'Favor de cargar todos los campos obligatorios.', 'error');
        }
      }
    });
  };
  
  
  

 

  return (
    <Paper sx={{ background: "#F1F1F2", borderRadius: 1, margin: '5px 5px', maxWidth: 450 }}>
      <Form onSubmit={handleSubmit}>
        <TextField
          autoComplete="off"
          size="small"
          type="text"
          variant="outlined"
          label="Titulo"
          name="titulo"
          sx={{ background: "#ffffff", borderRadius: 1 }}
          value={values.titulo ? values.titulo : ''}
          onChange={e => handleInputChange(e, "titulo", null, false)}
          error={Boolean(errors.titulo)}
          helperText={errors.titulo}
          FormHelperTextProps={{ sx: { color: 'red' } }}
        />

        <Grid container>
          <Grid item xs={6}>
            <Box sx={{ width: 240 }}>
              <FormControl fullWidth error={Boolean(errors.estado)}>
                <InputLabel id="demo-simple-select-label">Estado</InputLabel>
                <Select
                  value={values.estado ? values.estado : ''}
                  onChange={e => handleInputChange(e, "estado", null, false)}
                  name="estado"
                  size="small"
                  sx={{ background: "#ffffff", borderRadius: 1 }}
                >
                  {estadoOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error color="error">{errors.estado}</FormHelperText>
              </FormControl>
            </Box>
          </Grid>
          <TextField
            autoComplete="off"
            size="big"
            type="text"
            variant="outlined"
            label="Descripcion"
            name="descripcion"
            sx={{ background: "#ffffff", borderRadius: 1 }}
            value={values.descripcion ? values.descripcion : ''}
            onChange={e => handleInputChange(e, "descripcion", null, false)}
            error={Boolean(errors.descripcion)}
            helperText={errors.descripcion}
            FormHelperTextProps={{ sx: { color: 'red' } }}
          />
          <input
          type="file"
          accept=".pdf,.doc,.docx,.jpg,.jpeg,.png"
          onChange={handleFileChange}
          style={{ margin: '10px 0' }}
        />
          <Grid item xs={6}>
            <FormControlLabel
              sx={{ ml: "1%" }}
              control={<Checkbox onChange={e => handleInputChange(e, "llamada", null, false)} name="llamada" />}
              label="Llamada"
            />
            <FormControlLabel
              sx={{ ml: "1%" }}
              control={<Checkbox onChange={e => handleInputChange(e, "visita_presencial", null, false)} name="visita_presencial" />}
              label="Visita Presencial"
            />
            <FormControlLabel
              sx={{ ml: "1%" }}
              control={<Checkbox onChange={e => handleInputChange(e, "email", null, false)} name="email" />}
              label="Email"
            />
            <FormControlLabel
              sx={{ ml: "1%" }}
              control={<Checkbox onChange={e => handleInputChange(e, "finalizado", null, false)} name="finalizado" />}
              label="Finalizado"
            />
          </Grid>
        </Grid>

        <Button
          type="submit"
          className="add-subtask"
          variant="contained"
          sx={{
            fontWeight: 'bold',
            border: 1,
            borderColor: "black",
            margin: "3%",
            backgroundColor: '#BCBABE',
            color: '#111315',
            "&:hover": { borderColor: "#111315", background: "#BCBABE" }
          }}
        >
          {editMode ? 'EDITAR' : 'CREAR'}
        </Button>
      </Form>
    </Paper>
  )
}
