import React from "react";
import MenuAppBar from "../../components/MenuAppBar";
import "./IndustriasPage.css";
import Grid from '@mui/material/Grid';
import Industrias from "../../components/Industrias/Industrias";
import { Box } from "@mui/system";

function IndustriasPage() {
  const [sidebarVisible, setSidebarVisible] = React.useState(false);

  const toggleSidebar = () => {
    if (!sidebarVisible) {
      setSidebarVisible(true);
    } else {
      setSidebarVisible(false);
    }
  };

  return (
    <>
    <MenuAppBar handleBtnClick={toggleSidebar} />
    <Box sx={{marginTop:"5%", position: 'relative', top: '64px' }}>
    <Grid container justifyContent="center" spacing={3}> 
        <Industrias />
      </Grid>
    </Box>
    </>
  );
}

export default IndustriasPage;
